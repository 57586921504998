import React, { useState, useEffect } from "react";
import Table from "../Common/table";
import axios from "../httpServices/httpService";
import api from "../config";
import swal from "sweetalert";
import auth from "../httpServices/authService";
import validation from "../utill/validation";
import _ from "lodash";
import joi from "joi";
import Loading from "./../utill/LoadingComponent";
import date from "../utill/dateTime";
import EquipmentSelection from "../Common/equipmentSelection";
import * as XLSX from "xlsx";
import moment from "moment";
import { generateExcelFile } from "./download";

const SummaryReportTabReport = ({
  equips,
  equipGroups,
  reasons,
  qualityReason,
  data=[],
  storeDataInLocalStorage
}) => {
  const [productionData, setProductionData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([]);
  const [index, setIndex] = useState(0);
  const [querySuccess, setQuerySuccess] = useState(false);
  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });
  const [dynamicData, setDynamicData] = useState([]);

  const [Aggregate, setAggregate] = useState(false);
  const [TotalofDuration, setTotalofDuration] = useState([]);

  const schema = joi.object({
    From: joi.date().required().label("From Date"),
    UserId: joi.string(), // Optional by default
    Till: joi.date().required().label("Till Date"),
    Equipments: joi.array(), // Optional by default
    Shift: joi.array(), // Optional by default
    AggregateType: joi.string(), // Optional by default
  });
  const dataSheet1 = [
    { Name: "Alice", Age: 25 },
    { Name: "Bob", Age: 30 },
  ];

  const dataSheet2 = [
    { Product: "Laptop", Price: 1000 },
    { Product: "Phone", Price: 500 },
  ];

  // useEffect(() => {
  //   if(data && data.length >0){

  //       setProductionData(data);
  //   }

  // }, [data])
  
  const exportToExcel = () => {
    // Create a copy of the productionData and remove DowntimeSummary
    const Data = [...productionData];

    // Delete DowntimeSummary from each object in the Data array
    // Data.forEach(one => delete one.DowntimeSummary);

    // Create a workbook
    const workbook = XLSX.utils.book_new();

    // Convert each item in the Data array to a worksheet and add to the workbook
    Data.forEach((one, index) => {
      const sheet = XLSX.utils.json_to_sheet([one]); // wrap 'one' in an array
      XLSX.utils.book_append_sheet(workbook, sheet, "WorkSheet" + index);
    });

    // Write the workbook to a file
    XLSX.writeFile(workbook, "MultiSheetWorkbook.xlsx");
  };

  const handleOnChange = (e) => {
    if (e.currentTarget.type === "date") {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    } else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }
    setInputData(inputData);
    setErrors({});
  };
   useEffect(() => {
      // Fetch dynamic data using Axios
      const fetchData = async () => {
        try {
        setQuerySuccess(true);
  
          // const equipmentdata = await axios.get(api.api1 + "equipment");
      
          // const downtimedata = await axios.get(api.api + "downtimereason");
          let downtimedata = await storeDataInLocalStorage('downtimereason')
  
  
          const uniqueObjects = [];
          const downtimelist = downtimedata
          .map((reason, index) => ({
            id: `${index}`,
            label: reason.Reason,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
          // downtimelist.push({id:'40',label:"Down"})
  
          // Filter out objects with duplicate combinations of label and id
          const filteredData = downtimelist.filter(obj => {
            // Check if the combination of label and id is already in the uniqueObjects array
            const isDuplicate = uniqueObjects.some(uniqueObj =>
              uniqueObj.label === obj.label
            );
          
            if (!isDuplicate) {
              // If not, add it to the array and keep the object
              uniqueObjects.push({ id: obj.id, label: obj.label });
              return true;
            }
          
            // If the combination of label and id is already in the array, filter it out
            return false;
          });
          
          console.log(uniqueObjects,"dsvsjkdbv")
           
            
          setDynamicData(uniqueObjects);
       
          // consol("fetching data", uniqueObjects);
          
        } catch (error) {
          // console.error("Error fetching data:", error);
        setQuerySuccess(false);
  
        }
        setQuerySuccess(false);
      };
  
      fetchData();
    }, [])
  const handleOnSubmit = async (inputData) => {
    productionData.splice(0, productionData.length);
    setIndex(0);

    // inputData.Shift = [inputData.Shift];
    // const errors = validation.validate(inputData, schema);

    // setErrors(errors || {});
    // if (errors) return;
    setQuerySuccess(true);
    const user = auth.getCurrentUser();
    inputData.UserId = user._id;
    inputData.Equipment = getEquipsForReport();

    // for (let i = 0; i < equipForReport.length; i++) {

    //let data=[...productionData];
    try {
      let result = await axios.post(api.api + "GetDetailedProductionReport", inputData);

      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          result.data.forEach((one) => {
            productionData.push(one);
          });
          setProductionData(productionData);
          // setIndex(idx)
        }
        // else {
        //   setProductionData([])
        //   swal('Data Not Available');
        //   setQuerySuccess(false)
        // }
      } else {
        setQuerySuccess(false);
      }
    } catch (err) {
      swal(`${err}`);

      setQuerySuccess(false);
    }
    // }
    setQuerySuccess(false);
  };
  const getRowSpan = (data1, ReasonType) => {
    // return '3'
console.log(data1,"fkjufufuh")
    let ReasonArr = [...data1.DowntimeSummary
    ];
    let arr = [];
    let returnArr = [];
    for (let i = 0; i < ReasonArr.length; i++) {
      let arr1 = [];
      arr1.push(ReasonArr[i]);
      for (let j = i + 1; j < ReasonArr.length; j++) {
        if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
          arr1.push(ReasonArr[j]);
          ReasonArr.splice(j, 1);
          j--;
        }
      }
      arr.push(arr1);
    }
    arr.forEach((one) => {
      returnArr.push(one.length);
    });
    returnArr.sort(function (a, b) {
      return b - a;
    });
    if (returnArr.length > 0) {
      return returnArr[0];
    } else {
      return 1;
    }
  };

  const handleSort = (sortColumn) => {
    if (productionData.length > 0) {
      const sorted = _.orderBy(
        productionData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setProductionData(sorted);
      setSortColumn(sortColumn);
    }
  };

  const setSelection = (key, option, equips) => {
    if (key == "EquipmentGroup") {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option);
    }
    if (key == "Equipment") {
      setSelectedEquipOption(option);
    }
  };

  const getEquipsForReport = () => {
    let equipArr = [];
    equips.forEach((one) => {
      selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      });
    });
    return equipArr;
  };

  const getDowntimeReason = (downsReason, limit, ReasonType) => {
    let Total = [];
    let ReasonArr = [...downsReason];
    let arr = [];
    let returnArr = [];
    for (let i = 0; i < ReasonArr.length; i++) {
      let arr1 = [];
      arr1.push(ReasonArr[i]);
      for (let j = i + 1; j < ReasonArr.length; j++) {
        if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
          arr1.push(ReasonArr[j]);
          ReasonArr.splice(j, 1);
          j--;
        }
      }
      arr.push(arr1);
    }

    for (let j = 0; j < limit; j++) {
      if (j < limit) {
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i][j]) {
            arr2.push(arr[i][j]);
          }
        }
        returnArr.push(getDowntimeReason1(arr2, ReasonType));
      }
    }

    // arr.forEach((one,idx)=>{

    // })
    Total.push(returnArr);

    return returnArr;
  };

  const getDowntimeReason1 = (ReasonArr, Type) => {
    let returnArr = [];
    if ([...reasons.filter((one) => one.ReasonType === Type)].length == 0) {
      let obj = {};
      obj.Duration = null;
      obj.From = null;
      obj.Till = null;
      obj.Remarks = null;
      returnArr.push(obj);
    } else {
      [...reasons.filter((one) => one.ReasonType === Type)].forEach((one1) => {
        let filterReason = ReasonArr.filter(
          (obj) => obj.DowntimeReason == one1.Reason
        );
        if (filterReason.length > 0) {
          returnArr.push({ ...filterReason[0] });
        } else {
          let obj = {};
          obj.Duration = null;
          obj.From = null;
          obj.Till = null;
          obj.Remarks = null;
          returnArr.push(obj);
        }
      });
    }

    return returnArr;
  };
  function calculateDowntimeTotals(productionData, Type) {
    if (!productionData || productionData.length === 0) {
      return []; // Return empty array if no production data
    }

    // Calculate totals for each `one` in `productionData`
    const totals = productionData.map((one) => {
      const plannedDowntime = one.DowntimeSummary.filter(
        (reason) => reason.ReasonType === Type
      );

      const downtimeReasons = getDowntimeReason(
        plannedDowntime,
        getRowSpan(one, Type),
        Type
      );

      // Check if downtimeReasons is valid and calculate the total
      if (downtimeReasons.length > 0) {
        return downtimeReasons[0].map((_, index) =>
          downtimeReasons.reduce((sum, group) => sum + group[index].Duration, 0)
        );
      }

      return []; // Default value if there are no planned downtimes
    });

    // Calculate the overall total
    const result = totals[0]?.map((_, index) =>
      totals.reduce((sum, group) => sum + group[index], 0)
    );
    const overallTotal = calculateOverallTotal(totals);

    return { Total: result, overallTotal: overallTotal } || []; // Return overall result or empty array
  }

  function calculateOverallTotal(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return 0; // Return 0 if the data is empty or not an array
    }

    // Flatten the arrays and calculate the total sum
    const total = data.flat().reduce((sum, value) => sum + value, 0);
    return total;
  }

  const calculateTotalDuration = (data) => {
    return data.reduce((total, item) => total + item.Duration, 0);
  };
  const getQualityReason = (ReasonArr = []) => {
    let returnArr = [];
  
    // Check if `qualityReason` exists and is an array
    if (!Array.isArray(qualityReason) || qualityReason.length === 0) {
      returnArr.push({ Reason: 'default', Count: 0 });
    } else {
      qualityReason.forEach((one1) => {
        // Ensure `ReasonArr` is a valid array and filter accordingly
        const filterReason = Array.isArray(ReasonArr)
          ? ReasonArr.filter(obj => obj.Reason === one1.QualityReason)
          : [];
  
        if (filterReason.length > 0) {
          returnArr.push({ ...filterReason[0] });
        } else {
          let obj = { Reason: one1.QualityReason, Count: 0 };
          returnArr.push(obj);
        }
      });
    }
  
    console.log(qualityReason, ReasonArr, returnArr, "djhbcfbjhcf");
    return returnArr;
  };
  const handleDownloadTableToExcel = (tableId) => {
    generateExcelFile(tableId, new Date(Date.now()))
  }
    
  return (
    <React.Fragment>
      {querySuccess ? (
        <Loading
        // labelShow={true}
        // label={`${index}/${getEquipsForReport().length}`}
        />
      ) : (
        ""
      )}
      <div className="text-center">{/* <h6>Press Shop Report</h6> */}</div>
      <div className="row">
        <EquipmentSelection
          equipGroups={equipGroups}
          equip={equip}
          selectedEquipGroupOption={selectedEquipGroupOption}
          selectedEquipOption={selectedEquipOption}
          setSelection={setSelection}
        />
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              className={"form-control form-control-sm"}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
            />
            {errors.From && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.From}
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              className="form-control form-control-sm"
              type="date"
              placeholder="dd/mm/yyyy"
              onChange={handleOnChange}
              name="Till"
            />
            {errors.Till && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Till}
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Shift</label>
            <select
              className="form-control form-control-sm"
              onChange={handleOnChange}
              name="Shift"
            >
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Shift}
              </p>
            )}
          </div>
        </div>
        {/* <div className="col-sm-1">
          <div className="form-group text-center d-flex flex-column">
            <label className="small">Aggregate </label>
            <input
              className=""
              type="checkbox"
              checked={Aggregate}
              onClick={() => {
                if (Aggregate) {
                  setAggregate(false);
                  setInputData({ ...inputData, AggregateType: "-" });
                } else {
                  setAggregate(true);
                }
              }}
              name="Aggregate"
            />

            {errors.Aggregate && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Aggregate}
              </p>
            )}
          </div>
        </div>
        {Aggregate && (
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">Aggregate Type</label>
              <select
                className="form-control form-control-sm"
                onChange={handleOnChange}
                name="AggregateType"
              >
                <option>--select Aggregate Type--</option>
                <option value={"Equipment"}>Aggregate for Machine</option>
                <option value={"All"}>Aggregate for All</option>
                <option value={"EquipmentAndShift"}>
                  Aggregate for Equipment And Shift
                </option>
                <option value={"Shift"}>Aggregate for Shift</option>
              </select>
              {errors.Shift && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors.Shift}
                </p>
              )}
            </div>
          </div>
        )} */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit(inputData)}
          >
            Apply
          </button>
        </div>
    
        <div
          className={productionData.length > 0 ? "col-sm-1" : "col-sm-1 d-none"}
        >
          <a
            className="btn btn-sm btn-primary "
            onClick={() => handleDownloadTableToExcel("CustomFormulaTable")}
            style={{ float: "right", color: "white" }}
          >
            Download
          </a>
        </div>
      </div>
      <div>
        <div className="border border-dark p-1">
          <h6>Formulas</h6>
          <p style={{fontSize:'12px',padding:0}} className="text-nowrap text-black">
            {" "}
            Availibility % = ((Run Time )/ ((Total Time )- (Break) - (Planned
            Downtime))) * 100
          </p>
          <p style={{fontSize:'12px',padding:0}} className="text-nowrap text-black">
            {" "}
            Performance % = ((Actual Cycles) / ((  Available Time )/
            (Ideal Cycle Time))) * 100
          </p>

          <p style={{fontSize:'12px',padding:0}} className="text-nowrap text-black">
            {" "}
            Quality % = (((Parts Produced) - (Rejected * 100)) / Parts Produced)
          </p>
          <p style={{fontSize:'12px',padding:0}} className="text-nowrap text-black">
            {" "}
            Weighted Availibilty = ((Availibilty %) * (Job Duration))
            </p>
            <p style={{fontSize:'12px',padding:0}} className="text-nowrap text-black">
              {" "}
              Weighted Performance = ((Performance %) * (Job Duration))
          </p>
          <p style={{fontSize:'12px',padding:0}} className="text-nowrap text-black">
            {" "}
            Weighted Quality = ((Quality %) * (Job Duration))
          </p>
        </div>
        {/* {productionData.length > 0 ? <Table columns={ProductionReportSchema} onSort={handleSort} id='ProductionReport'  sortColumn={sortColumn} data={productionData} /> : <p></p>} */}
<div 
// className="
// table-responsive 
// "
>


        {productionData.length > 0 ? (
          <table
            id="CustomFormulaTable"
            className="table table-sm table-bordered table-hover small table-striped "
            style={{ padding: 0 }}
          >
            <thead
              style={{ top: 0, position: "sticky" }}
              className="small text-center border"
            >
              <tr className="text-center align-self-center table-primary">
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Job Available
                </th>

                {inputData.AggregateType == "Equipment" ||
                inputData.AggregateType == "All" ||
                inputData.AggregateType == "EquipmentAndShift" ||
                inputData.AggregateType == "Shift" ? (
                  ""
                ) : (
                  <th style={{ verticalAlign: "middle" }} rowSpan="3">
                    Date
                  </th>
                )}
                {inputData.AggregateType == "All" ||
                inputData.AggregateType == "EquipmentAndShift" ? (
                  ""
                ) : (
                  <th
                    colSpan={
                      inputData.AggregateType == "All" ||
                      inputData.AggregateType == "EquipmentAndShift"
                        ? 2
                        : 1
                    }
                    style={{ verticalAlign: "middle" }}
                    rowSpan="3"
                  >
                    Equipment
                  </th>
                )}
                {inputData.AggregateType == "EquipmentAndShift" ||
                inputData.AggregateType == "All" ? (
                  ""
                ) : (
                  <th style={{ verticalAlign: "middle" }} rowSpan="3">
                    Shift
                  </th>
                )}
                <th
                  colSpan={inputData.AggregateType == "All" ? 2 : 1}
                  style={{ verticalAlign: "middle" }}
                  rowSpan="3"
                >
                  Component
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Operation
                </th>

                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  From Time
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Till Time
                </th>
                

                <th
                  className="table-primary"
                  style={{ verticalAlign: "middle" }}
                  colSpan={reasons.length + 2}
                >
                  STOPPAGE
                </th>
                <th rowSpan={3}>Total Planned Downtime Duration (mins)</th>
                <th rowSpan={3}>Total  Unplanned Downtime Duration (mins) </th>
                <th rowSpan={3}>Total  Break Duration (mins)</th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Run Time  (mins)
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Ideal Cycle Time (mins)
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Ideal Cycles
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan={3}> Actual Cycles</th>

                <th style={{ verticalAlign: "middle" }} rowSpan={3}>Parts Per Cycle</th>

                <th style={{ verticalAlign: "middle" }} rowSpan={3}>Parts Produced</th>
               
              
                
                {/* <th rowSpan={3}>No Of M/c B/W</th>
                <th rowSpan={3}>MTTR (min)</th>
                <th rowSpan={3}>MTBF (min)</th>
                <th rowSpan={3}>No Of Tool B/W</th>

                <th rowSpan={3}>MTTR (min)</th>
                <th rowSpan={3}>MTBF (min)</th> */}
                 <th rowSpan={2} colSpan={qualityReason.length}>
                Rejection Reason
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan={3}>Job Duration (mins)</th>
                <th style={{ verticalAlign: "middle" }} rowSpan={3}>No Job Duration (mins) </th>

                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Availibilty %
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Performance %
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Quality %
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Job OEE %
                </th>

                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Weighted Availibilty
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Weighted Performance
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                  Weighted Quality
                </th>
              </tr>
             
              <tr className="table-primary">
                <th
                  colSpan={
                    reasons &&
                    [...reasons.filter((one) => one.ReasonType === "Planned")]
                      .length + 1
                  }
                >
                  PLANNED STOPPAGE
                </th>
                <th
                  colSpan={
                    reasons &&
                    [...reasons.filter((one) => one.ReasonType === "Unplanned")]
                      .length + 1
                  }
                >
                  UNPLANNED STOPPAGE
                </th>
              </tr>
              <tr className="table-primary">
                {[...reasons.filter((one) => one.ReasonType === "Planned")].map(
                  (one, index) => (
                    <th key={index}>{one.Reason}</th>
                  )
                )}
                <th>Total</th>
                {[
                  ...reasons.filter((one) => one.ReasonType === "Unplanned"),
                ].map((one, index) => (
                  <th key={index}>{one.Reason}</th>
                ))}
                <th>Total</th>
               
                {qualityReason.length > 0 ? qualityReason.map(one => {
                        return <th   ><div >{one.QualityReason}</div></th>
                    }) : ''}
              </tr>
              {/* <tr className='table-primary' >
             

                 

                   

                </tr> */}
            </thead>
            <tbody>
              {productionData.map((one) => {
                return (
                  <>
                    <tr>
                      <td>{one.DocumentType == "JobSummary" ? "Yes" : "No"}</td>

                      {inputData.AggregateType == "Equipment" ||
                      inputData.AggregateType == "All" ||
                      inputData.AggregateType == "EquipmentAndShift" ||
                      inputData.AggregateType == "Shift" ? (
                        ""
                      ) : (
                        <td>{date.getDateFormat(one.ShiftOf)}</td>
                      )}
                      {inputData.AggregateType == "All" ||
                      inputData.AggregateType == "EquipmentAndShift" ? (
                        ""
                      ) : (
                        <td>{one.Equipment}</td>
                      )}
                      {inputData.AggregateType == "EquipmentAndShift" ||
                      inputData.AggregateType == "All" ? (
                        ""
                      ) : (
                        <td>{one.ShiftName}</td>
                      )}
                      <td colSpan={inputData.AggregateType == "All" ? 2 : 1}>
                        {one.Component}
                      </td>
                      <td>{one.Operation}</td>

                      <td>
                        {one.From ? moment(one.From).format("hh:mm a") : "-"}
                      </td>
                      <td>
                        {one.From ? moment(one.Till).format("hh:mm a") : "-"}
                      </td>
                      

                      {getDowntimeReason(
                        [
                          ...one.DowntimeSummary.filter(
                            (one) => one.ReasonType === "Planned"
                          ),
                        ],
                        getRowSpan(one, "Planned"),
                        "Planned"
                      ).length > 0
                        ? getDowntimeReason(
                            [
                              ...one.DowntimeSummary.filter(
                                (one) => one.ReasonType === "Planned"
                              ),
                            ],
                            getRowSpan(one, "Planned"),
                            "Planned"
                          ).map((o, i) => {
                            if (i == 0) {
                              return o.map((r) => {
                                return (
                                  <>
                                    <td>{r.Duration ? r.Duration : 0}</td>
                                  </>
                                );
                              });
                            }
                          })
                        : ""}
                      <td>
                        {calculateTotalDuration([
                          ...one.DowntimeSummary.filter(
                            (one) => one.ReasonType === "Planned"
                          ),
                        ])}
                      </td>
                      {getDowntimeReason(
                        [
                          ...one.DowntimeSummary.filter(
                            (one) => one.ReasonType === "Unplanned"
                          ),
                        ],
                        getRowSpan(one, "Unplanned"),
                        "Unplanned"
                      ).length > 0
                        ? getDowntimeReason(
                            [
                              ...one.DowntimeSummary.filter(
                                (one) => one.ReasonType === "Unplanned"
                              ),
                            ],
                            getRowSpan(one, "Unplanned"),
                            "Unplanned"
                          ).map((o, i) => {
                            if (i == 0) {
                              return o.map((r) => {
                                return (
                                  <>
                                    <td>{r.Duration ? r.Duration : 0}</td>
                                  </>
                                );
                              });
                            }
                          })
                        : ""}
                      <td>
                        {Number(
                          calculateTotalDuration([
                            ...one.DowntimeSummary.filter(
                              (one) => one.ReasonType === "Unplanned"
                            ),
                          ])
                        ).toFixed(1)}
                      </td>
                      <td>{one.PlannedDowntimeDuration}</td>
                      <td>{one.UnplannedDowntimeDuration}</td>
                      <td>{one.BreakDuration}</td>

                      <td>{one.RunTime}</td>
                      <td>
                        {one.IdealCycleTime ? Math.ceil(one.IdealCycleTime) : 0}
                      </td>
                      <td>
                        {one.IdealCycles ? Math.ceil(one.IdealCycles) : 0}
                      </td>
                      <td>{one.ActualCycles}</td>

                      <td>{one.JobPartsPerCycle}</td>
                      <td>{one.PartsProduced}</td>
                      
                      {/* <td>
                        {one.RejectionReason && one.RejectionReason.length > 0
                          ? one.RejectionReason.map((item) => item.Reason).join(
                              ", "
                            )
                          : 0}
                      </td> */}

                     
                    
                      {getQualityReason(one.RejectionReason).length > 0 ? getQualityReason(one.RejectionReason).map(r => {
                                return <td rowSpan={getRowSpan(one)}>{r.Count}</td>
                            }) : <td rowSpan={getRowSpan(one)}>0</td>}
                             <td>{one.JobDuration ?? 0}</td>
                      <td>{one.NoJobDuration ?? 0}</td>

                      <td>{one.Availability}</td>
                      <td>{one.Performance}</td>
                      <td>{one.QualityFactor}</td>
                      <td>{one.OEE}</td>

                      <td>
                        {(
                          (one.JobDuration || one.NoJobDuration) *
                          Number(one.Availability)
                        ).toFixed(1) ?? 0}
                      </td>
                      <td>
                        {(
                          (one.JobDuration || one.NoJobDuration) *
                          Number(one.Performance)
                        ).toFixed(1) ?? 0}
                      </td>

                      <td>
                        {(
                          (one.JobDuration || one.NoJobDuration) *
                          Number(one.QualityFactor)
                        ).toFixed(1) ?? 0}
                      </td>
                    </tr>
                    
                  </>
                );
              })}
              <tr className="font-weight-bold">
                <td colSpan={8}>
                  Total
                </td>
              {/* Planned Downtime */}

                {calculateDowntimeTotals(productionData, "Planned") &&
            calculateDowntimeTotals(productionData, "Planned").Total &&
            calculateDowntimeTotals(productionData, "Planned").Total.map(
              (Duration) => <td>{(Number(Duration)).toFixed(0)}</td>
            )}

          {calculateDowntimeTotals(productionData, "Planned") &&
            calculateDowntimeTotals(productionData, "Planned")
              .overallTotal && (
              <td>
                {
                  calculateDowntimeTotals(productionData, "Planned")
                    .overallTotal
                }
              </td>
            )}

          {calculateDowntimeTotals(productionData, "Unplanned") &&
            calculateDowntimeTotals(productionData, "Unplanned").Total &&
            calculateDowntimeTotals(
              productionData,
              "Unplanned"
            ).Total.map((Duration) => <td>{(Number(Duration)).toFixed(0)}</td>)}
          {calculateDowntimeTotals(productionData, "Unplanned") &&
            calculateDowntimeTotals(productionData, "Unplanned")
              .overallTotal && (
              <td>
                {
                  calculateDowntimeTotals(productionData, "Unplanned")
                    .overallTotal
                }
              </td>
            )}
                <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.PlannedDowntimeDuration ?? 0), 0)).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.UnplannedDowntimeDuration ?? 0), 0)).toFixed(0)}
                </td>
                <td>
                  {productionData
                    .reduce((sum, one) => sum + Number(one.BreakDuration ?? 0), 0)
                    .toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce(
                    (sum, one) => sum + Number(one.RunTime ?? 0),
                    0
                  ).toFixed(0))}
                </td>
                <td>
                {/* {(productionData.reduce((sum, one) => sum + Number(one.IdealCycleTime ? Math.ceil(one.IdealCycleTime) : 0), 0)).toFixed(0)} */}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.IdealCycles ? Math.ceil(one.IdealCycles) : 0), 0)).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.ActualCycles ?? 0), 0)).toFixed(0)}
                </td>
                <td>
                  {/* {(productionData.reduce((sum, one) => sum + Number(one.JobPartsPerCycle ?? 0), 0) ).toFixed(0)} */}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.PartsProduced ?? 0), 0)).toFixed(0)}
                </td>
                <td colSpan={ qualityReason.length}>
                 
                </td>
               
                <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0)).toFixed(0)}
                </td> <td>
                  {(productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)).toFixed(0)}
                </td>
                
                <td>
                  {((productionData.reduce(
                    (sum, one) => sum + Number(one.Availability ?? 0),
                    0
                  ))/productionData.length).toFixed(1)}
                </td>
                <td>
                  {((productionData.reduce(
                    (sum, one) => sum + Number( one.Performance ?? 0),
                    0
                  ))/productionData.length).toFixed(1)}
                </td>
                <td>
                  {((productionData.reduce(
                    (sum, one) => sum + Number(one.QualityFactor ?? 0),
                    0
                  ))/productionData.length).toFixed(1)}
                </td>
                <td>{((productionData.reduce((sum, one) => sum + Number(one.OEE ?? 0), 0))/productionData.length).toFixed(1)}</td>
               
               
                <td>
                  {(productionData.reduce((sum, one) => sum + Number((
                          (one.JobDuration || one.NoJobDuration) *
                          Number(one.Availability ?? 0)
                        ).toFixed(1) ?? 0), 0)).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + Number((
                          (one.JobDuration || one.NoJobDuration) *
                          Number(one.Performance ?? 0)
                        ).toFixed(1) ?? 0), 0)).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + Number((
                          (one.JobDuration || one.NoJobDuration) *
                          Number(one.QualityFactor ?? 0)
                        ).toFixed(1) ?? 0), 0)).toFixed(0)}
                </td>
                


         
      
        </tr>
            </tbody>
          </table>
        ) 

        : (
          ""
        )}
        
        <div className="d-flex flex-column">
   { productionData && productionData.length > 0 &&     <div>
  <div>
    Total Duration = 
    {(
      productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
      productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
    ).toFixed(0)}
  </div>

  <div>
    Total Weighted Availability = 
    {productionData.reduce((sum, one) => 
      sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.Availability ?? 0)), 0).toFixed(0)}
  </div>

  <div>
    Total Weighted Performance = 
    {productionData.reduce((sum, one) => 
      sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.Performance ?? 0)), 0).toFixed(0)}
  </div>

  <div>
    Total Weighted Quality = 
    {productionData.reduce((sum, one) => 
      sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.QualityFactor ?? 0)), 0).toFixed(0)}
  </div>

  <div>
    Availability % = 
    {(
      productionData.reduce((sum, one) => 
        sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.Availability ?? 0)), 0) /
      (
        productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
        productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
      )
    ).toFixed(2) ?? 0}
  </div>

  <div>
    Performance % = 
    {(
      productionData.reduce((sum, one) => 
        sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.Performance ?? 0)), 0) /
      (
        productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
        productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
      )
    ).toFixed(2) ?? 0}
  </div>

  <div>
    Quality % = 
    {(
      productionData.reduce((sum, one) => 
        sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.QualityFactor ?? 0)), 0) /
      (
        productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
        productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
      )
    ).toFixed(2) ?? 0}
  </div>
  <div>
    OEE % = 
    {(Number((
      productionData.reduce((sum, one) => 
        sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.Availability ?? 0)), 0) /
      (
        productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
        productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
      )
    ).toFixed(2) ?? 0)*
    Number((
      productionData.reduce((sum, one) => 
        sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.QualityFactor ?? 0)), 0) /
      (
        productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
        productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
      )
    ).toFixed(2) ?? 0)*
    Number((
      productionData.reduce((sum, one) => 
        sum + (Number(one.JobDuration || one.NoJobDuration || 0) * Number(one.Performance ?? 0)), 0) /
      (
        productionData.reduce((sum, one) => sum + Number(one.JobDuration ?? 0), 0) +
        productionData.reduce((sum, one) => sum + Number(one.NoJobDuration ?? 0), 0)
      )
    ).toFixed(2) ?? 0))/10000}
  </div>
</div>}
</div>

        </div>
      </div>
    </React.Fragment>
  );
};
export default SummaryReportTabReport;
