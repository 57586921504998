import React, { useEffect, useState, useRef } from "react";
import Loading from "../../utill/LoadingComponent";
import ProductionCountDashboardTable from '../../utill/productionCountDashboardTable1';
import axios from "../../httpServices/httpService";
import api from "../../config.json";
import { Radio } from "antd";
import { use } from "react";

const HourlyCountdashBoard = ({ equips }) => {
  const today = new Date();
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("None");
  const widgetsRef = useRef([]); // Ref to hold the latest widgets state

  const keyParam = localStorage.getItem("HourlyCountParam");
  useEffect(() => {
    if (keyParam) {
      setSelectedValue(keyParam);
    }
  }, [keyParam]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      const newWidgets = [];

      for (const equip of equips) {
        const widget = {
          Title: "7 Days AVG SPM",
          apiUrl: "GetCyclesHourly",
          inputData: {
            Equips: [equip],
            DateTime: today,
            Shift: "Shift1",
            UserId: "62c67bc9654fcd38ba51a77c",
          },
        };

        try {
          const result = await axios.post(api.api + widget.apiUrl, widget.inputData);
          newWidgets.push(...result.data);
        } catch (error) {
          console.error("Error fetching initial data:", error);
        }
      }

      // Update state and ref
      setWidgets(newWidgets);
      widgetsRef.current = newWidgets;
      setLoading(false);
    };

    const updateData = async () => {
      setLoading(true);
      try {
        const updatedWidgets = await Promise.all(
          widgetsRef.current.map(async (widget) => {
            const result = await axios.post(api.api + widget.apiUrl, widget.inputData);
            return { ...widget, apiData: result.data };
          })
        );

        // Update state and ref
        setWidgets(updatedWidgets);
        widgetsRef.current = updatedWidgets;
      } catch (error) {
        console.error("Error updating data:", error);
      } finally {
        setLoading(false);
      }
    };

    const calculateNextInterval = () => {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const milliseconds = now.getMilliseconds();
      const nextIntervalMinutes = (Math.ceil(minutes / 10) * 10) % 60;
      const delay =
        (nextIntervalMinutes - minutes) * 60 * 1000 -
        seconds * 1000 -
        milliseconds;
      return delay;
    };

    // Fetch initial data
    fetchInitialData();

    // Set up interval for periodic updates
    const timeout = setTimeout(() => {
      updateData();
      const interval = setInterval(updateData, 600000); // Update every 10 minutes
      return () => clearInterval(interval);
    }, calculateNextInterval());

    return () => clearTimeout(timeout); // Cleanup timeout
  }, [equips]); // Re-run if `equips` changes

  return (
    <div style={{ width: "100%" }}>
      {loading && <Loading />}
      <h3 className="text-center d-flex align-items-center justify-content-between">
        <div>
          <Radio.Group onChange={(e) => { setSelectedValue(e.target.value); localStorage.setItem("HourlyCountParam", e.target.value) }} value={selectedValue} buttonStyle="solid">
            <Radio value={"AvgSPM"}>Average SPM</Radio>
            <Radio value={"AvgCycleTime"} >Average Cycle Time</Radio>
            <Radio value={"None"} >None</Radio>
          </Radio.Group>
        </div>
        <div className="mx-auto">
          Live Hourly Count
        </div>
      </h3>
      {widgets.length > 0 ? (
        <ProductionCountDashboardTable extraParam={selectedValue} data={widgets} />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default HourlyCountdashBoard;
