import React, { useState } from "react";
import date from "../utill/dateTime";
import moment from "moment";
import _ from "lodash";

const ProductionCountEquipmentGroup = ({
  data,
  rawData,
  className,
  DateTime,
  dashBoard,
}) => {
  console.log(data, "data format test");
  const handelGrouped = (data) => {
    const groupedData = _.groupBy(data, "EquipmentType");

    // Transform the grouped data to add EquipmentGroup to each group
    const transformedData = Object.keys(groupedData).map((key) => ({
      EquipmentType: key,
      EquipmentItems: groupedData[key],
    }));
    console.log('testingdjghdsf')
    return transformedData;
  };
  const SortedData = data.sort((a, b) => a.EquipmentGroup.localeCompare(b.EquipmentGroup));
  console.log(SortedData, "grouped sorted");
  
  return (
    <>
      <table
        id="HorlyCountEquipmentGroupWise"
        style={{ fontSize: "14px" }}
        className="table table-sm table-hover small table-bordered table-striped w-100"
      >
        <thead className="small text-center border">
          <tr className="table-primary">
            <th rowSpan="2">Equipment Group</th>
            {dashBoard !== true ? <>
            <th rowSpan="2">Date</th>
            <th rowSpan="2">Shift</th>
            </>:''
            }
            <th rowSpan="2">Equipment</th>
            {data.length > 0 &&
              data[0].EquipmentItems[0].Hours.map((hour, index) => (
                <th key={index} colSpan="1">
                  <div className="mt-2">
                    <span className="text-nowrap">
                      {new Date(hour.From).toLocaleTimeString()} -{" "}
                      {new Date(hour.Till).toLocaleTimeString()}
                    </span>
                  </div>
                </th>
              ))}
              <th ></th>

          </tr>
          <tr className="table-primary">
            {data.length > 0 &&
              data[0].EquipmentItems[0].Hours.map((_, index) => (
                <th key={index}>CyclesRun</th>
              ))}
              <th >Total</th>
          </tr>
        </thead>

        <tbody className="text-center">
          {data &&
            data.length > 0 &&
            data.map((one, index) => {
              // Initialize totals array for this EquipmentGroup
              const totals = one.EquipmentItems[0].Hours.map(() => 0);
              return (
                <React.Fragment key={index}>
                  <tr
                    className={`text-center ${className}`}
                    style={{ fontSize: "20px" }}
                  >
                    <td rowSpan={one.EquipmentItems.length + 1}>
                      {one.EquipmentGroup}
                    </td>
                  </tr>

                  {one.EquipmentItems.map((one2, one2Index) => (
                    <tr key={one2Index} className={`text-center ${className}`}>
                       {dashBoard !== true ? <>
                        <td>{moment(DateTime).format("DD-MMM-YY")}</td>
                        <td>{one2.Shift}</td>
            </>:''
            }
                     
                      <td>{one2.Equipment}</td>

                      {one2.Hours.map((hour, hourIndex) => {
                        // Add CyclesRun to the total for this column
                        totals[hourIndex] += hour.CyclesRun;
                        return <td key={hourIndex}>{hour.CyclesRun}</td>;
                      })}
                        
                        <td colSpan="1">{one2.Hours.reduce(
                (accumulator, currentValue) => accumulator + currentValue.CyclesRun,
                0,
              )}</td>
                    </tr>
                  ))}

                  {/* Totals Row */}
                  <tr
                    className={`bg-secondary text-center font-weight-bold ${className}`}
                  >
                    <td colSpan={dashBoard?'2':"4"}>Total</td>
                    {totals.map((total, totalIndex) => (
                      <td key={totalIndex}>{total}</td>
                    ))}
                     <td colSpan="1">{totals.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
              )}</td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
      <table
        style={{ fontSize: "14px" }}
        className="table table-sm table-hover small table-bordered table-striped w-100"
      >
        <thead className="small text-center border">
          <tr className="table-primary">
            <th rowSpan="2" colSpan={4}>
              Equipment Type
            </th>

            {data.length > 0 &&
              data[0].EquipmentItems[0].Hours.map((hour, index) => (
                <th key={index} colSpan="1">
                  <div className="mt-2">
                    <span className="text-nowrap">
                      {new Date(hour.From).toLocaleTimeString()} -{" "}
                      {new Date(hour.Till).toLocaleTimeString()}
                    </span>
                  </div>
                </th>
              ))}
                <th ></th>

          </tr>
          <tr className="table-primary">
            {data.length > 0 &&
              data[0].EquipmentItems[0].Hours.map((_, index) => (
                <th key={index}>CyclesRun</th>
              ))}
                <th >Total</th>

          </tr>
        </thead>

        <tbody className={`text-center ${className}`}>
          {handelGrouped(rawData) &&
            handelGrouped(rawData).length > 0 &&
            handelGrouped(rawData).map((one, index) => {
              // Initialize totals array for this EquipmentGroup
              const totals = one.EquipmentItems[0].Hours.map(() => 0);

              return (
                <React.Fragment key={index}>
                  <tr
                    className={`text-center ${className}`}
                    style={{ fontSize: "20px" }}
                  >
                    <td colSpan={3} rowSpan={2 }>
                      {one.EquipmentType}
                    </td>
                  </tr>

                  {one.EquipmentItems.map((one2, one2Index) => (
                    <>
                      {one2.Hours.map((hour, hourIndex) => {
                        // Add CyclesRun to the total for this column
                        totals[hourIndex] += hour.CyclesRun;
                      })}
                    </>
                  ))}

                  {/* Totals Row */}
                  <tr
                    className={`bg-secondary text-center font-weight-bold ${className}`}
                  >
                    <td colSpan="1">Total</td>
                    {totals.map((total, totalIndex) => (
                      <td key={totalIndex}>{total}</td>
                    ))}
                    <td colSpan="1">{totals.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
              )}</td>
                    
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default ProductionCountEquipmentGroup;
