import React, { useState, useRef } from "react";
import MyChart from "../utill/Barchart";
import GaugeChart from "../utill/GaugeChart";
import MyChartComponent from "../utill/StackedBarChart";
import BasicTabs from "../utill/TabsComponent";
// import * as React from 'react';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import YourDatePickerComponent from "../utill/DateRangePicker";
import YourDateRangePickerComponent from "../utill/DateRangePicker";
import axios from "../httpServices/httpService";
import dateTime from "../utill/dateTime";
import api from "../config";
import { saveAs } from "file-saver";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Loading from "../utill/LoadingComponent";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import validation from "../utill/validation";
import joi from "joi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import EquipmentSelection from "../Common/equipmentSelection";
import Availability from "./Availability";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";

// import MenuUp from 'mdi-material-ui/MenuUp'
// import DotsVertical from 'mdi-material-ui/DotsVertical'
import Avatar from "@mui/material/Avatar";
import SummaryReportTabReport from "../utill/SummaryReport1tab";

// ** Icons Imports
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Shifts = [
  { shift: "shift 1" },
  { shift: "shift 2" },
  { shift: "shift 3" },
];
const LiveDashBoards = ({
  equipGroups,
  pagetitle,
  storeDataInLocalStorage,
  equipList,
  reasons,
  qualityReason,
   Heading,
}) => {
  const [value, setValue] = React.useState(0);

  const tableRef = useRef(0);
  const [inputData, setInputData] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [Gaugdata, setGaugdata] = useState([]);
  const [GaugeCartData, setGaugeCartData] = useState([]);
  const [bardata, setbardata] = useState({});
  const [PerformanceData, setPerformanceData] = useState({});
  const [AvailibilityData, setAvailibilityData] = useState({});
  const [QfData, setQfData] = useState({});

  console.log(AvailibilityData, "available availability");
  const [DayWiseOee, setDayWiseOee] = useState({});
  const [DayLossHrs, setDayLossHrs] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [APQdata, setAPQdata] = useState({});

  const [MonthlyDownTimeReason, setMonthlyDownTimeReason] = useState({});
  const [TotalRejection, setTotalRejection] = useState({});
  const [Dashboard, setDashboard] = useState("");
  const [operatordata, setoperatordata] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [errors, setErrors] = useState({});
  const [componentdata, setcomponentdata] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  const [Qualityreason, setQualityreason] = useState([]);
  const [DownTimeReasons, setDownTimeReasons] = useState([]);
  const [HideDropdown, setHideDropdown] = useState(false);

  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [ShiftTotal, setShiftTotal] = useState({});
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([]);
  const [Efficiency, setEfficiency] = useState({});

  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  
  const data = [
    {
      progress: 75,
      imgHeight: 20,
      title: "Zipcar",
      color: "primary",
      amount: "$24,895.65",
      subtitle: "Vuejs, React & HTML",
      imgSrc: "/images/cards/logo-zipcar.png",
    },
    {
      progress: 50,
      color: "info",
      imgHeight: 27,
      title: "Bitbank",
      amount: "$8,650.20",
      subtitle: "Sketch, Figma & XD",
      imgSrc: "/images/cards/logo-bitbank.png",
    },
    {
      progress: 20,
      imgHeight: 20,
      title: "Aviato",
      color: "secondary",
      amount: "$1,245.80",
      subtitle: "HTML & Angular",
      imgSrc: "/images/cards/logo-aviato.png",
    },
  ];
  const DropDownData = [
    // {
    //   heading: "Equipment",
    //   label: "tablecolumns",
    //   initialItems: equpdata,
    //   selectedItemIds: selectedItemIds,
    //   name: "Equipment",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // },
    {
      heading: "Component",
      label: "tablecolumns",
      initialItems: componentdata,
      selectedItemIds: selectedItemIds,
      name: "Component",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll: true,
    },
    {
      heading: "Operator",
      label: "tablecolumns",
      initialItems: operatordata,
      selectedItemIds: selectedItemIds,
      name: "Operator",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll: true,
    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll: true,
    },
  ];
  const DropDownData1 = [
    {
      heading: "Equipment",
      label: "tablecolumns",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll: true,
    },
  ];

  const schema = {
    From: joi.string().required().label("From"),
    Till: joi.string().required().label("Till"),
    Equipment: joi.array().required().label("Equipment"),
    Operator: joi.array().required().label("Operator"),
    Shift: joi.array().required().label("Shift"),
    Component: joi.array().required().label("Component"),
  };
  const schema1 = {
    From: joi.string().required().label("From"),
    Till: joi.string().required().label("Till"),
    Equipment: joi.array().required().label("Equipment"),
  };

  const setSelection = (key, option, equips) => {
    if (key == "EquipmentGroup") {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option);
      setSelectedEquipOption([]);
    }
    if (key == "Equipment") {
      setSelectedEquipOption(option);
    }
  };

  const Barchartcolor = [
    "rgba(255, 132, 72, 1)",
    "rgba(75, 175, 225, 0.8)",
    "rgba(59, 199, 146, 0.46)",
  ];
  const targetRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const handleDateChange = (dateRange) => {
    if (new Date(dateRange.startDate) === new Date(dateRange.endDate)) {
      setHideDropdown(true);
    }

    if (new Date(dateRange.startDate) !== new Date(dateRange.endDate)) {
      setHideDropdown(false);
    }

    setSelectedDateRange(dateRange);
  };

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = ["Equipment", "Component", "Operator", "Shift"];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    inputData[name] =
      newSelectedItems.length == totallength ? ["all"] : newSelectedItems;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let componentdata = await storeDataInLocalStorage("component");
        let downtimedata = await storeDataInLocalStorage("downtimereason");

        let operatordata = await storeDataInLocalStorage("operator");

        let equipmentdata = await storeDataInLocalStorage("equipment");
        let qualitydata = await storeDataInLocalStorage("qualityreason");

        const complist = componentdata
          .map((componentList, index) => ({
            id: `${index}`,
            label: componentList.ComponentId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        const operatorlist = operatordata
          .map((operator, index) => ({
            id: `${index}`,
            label: operator.Name,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setequpdata(equiplist);
        setoperatordata(operatorlist.sort((a, b) => a[1] - b[1]));
        setcomponentdata(complist);
        setQualityreason(qualitydata);
        setDownTimeReasons(downtimedata);
        SubmitData("preload");
        setQuerySuccess(false);
      } catch (error) {
        setQuerySuccess(false);

        // console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const callFunctionRepeatedly = (func, intervalInMilliseconds) => {
    // consol(`Calling the provided function every ${intervalInMilliseconds / 1000} seconds...`);

    // Set an interval to call the function every specified interval
    setInterval(func, intervalInMilliseconds);
  };

  // Call the function to start the process
  // useEffect(()=>{
  const SubmitData = async (type) => {
    const data = { ...inputData };
    let TotalData = [];
    let TotalEfficiency = [];
    let TotalData1 = [];
    let TotalData2 = [];
    let TotalData3 = [];

    let totalReasonData = [];
    const from =
      selectedDateRange[0]?.startDate?.length > 0
        ? selectedDateRange[0].startDate
        : moment().clone().add(-1, "day").format("YYYY-MM-DD");
    const till =
      selectedDateRange[0]?.endDate?.length > 0
        ? selectedDateRange[0].endDate
        : moment().clone().add(-1, "day").format("YYYY-MM-DD");
    data.From = from;
    data.Till = till;

    let testdata = [];

    // Get today's date
    const today = new Date();
    const todayDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    // Assume selectedDate is a string in the format 'DD MM YYYY'
    const selectedDateInput = from;
    const selectedDateParts = selectedDateInput.split(" ");
    const selectedDate = selectedDateParts.join("-");

    // Check if the selected date is equal to today's date
    if (selectedDate === todayDate) {
      // consol('The selected date is equal to today\'s date.',selectedDate,todayDate);
      let filteredList;
      if (
        data?.Equipment &&
        data.Equipment.length > 0 &&
        data.Equipment[0] === "all"
      ) {
        filteredList = equipList;
      } else {
        filteredList = await equipList.filter((item) =>
          data.Equipment.some((one) => one === item.EquipmentID)
        );
      }
      for (let i = 0; i < filteredList.length; i++) {
        try {
          // setQuerySuccess(true);
          let response = await axios.post(
            api.api1 + "getLiveDataForPlantReport",
            { Equips: [filteredList[i]] }
          );
          if (response.data.summaryData.length > 0) {
            // consol(response.data,"live data recived from an api",data);
            testdata.push(response.data);
          }
        } catch (err) {
          // consol(err)
          setQuerySuccess(false);
        }
      }
      if (testdata?.length > 0) {
        let OeeData1 = [];
        let Equipment = [];
        let PerformanceData1 = [];
        let DownTimeReason = [];
        let DownTimeDuration = [];
        let TotalMachineRunningHrs = 0;
        let TotalMachineStopHrs = 0;
        let TotalProductionQuantity = 0;
        let TotalRejectionQuantity = 0;
        let RejectionPercentage = 0;
        let dayWiseLossHrs = 0;
        let Shift1Losshrs = 0;
        let Shift2Losshrs = 0;
        let Performance = 0;
        let currentshift;
        let chartData;
        const totalOEE = testdata.reduce((sum, item) => {
          return sum + Number(item.summaryData[0].OEE);
        }, 0);
        const totalLossHrs = testdata.reduce((sum, item) => {
          return sum + Number(item.summaryData[0].TotalDownTime || 0);
        }, 0);
        const averageOEE = (totalOEE / testdata.length).toFixed(1);
        let data1 = {
          title: testdata[0].summaryData[0].Shift,
          data: averageOEE,
          color1: "#3d87e6",
          color2: "#2a73ff",
        };

        currentshift = testdata[0].summaryData[0].Shift;

        let dayWiseOEE = testdata.map((item, i) => {
          OeeData1.push(item.summaryData[0].OEE);
          Equipment.push(item.summaryData[0].Equipment);
          PerformanceData1.push(item.summaryData[0].Performance);

          TotalMachineStopHrs =
            Number(item.summaryData[0]?.TotalDownTime || 0) +
            TotalMachineStopHrs;
          TotalMachineRunningHrs =
            Number(item.summaryData[0]?.RunTime || 0) + TotalMachineRunningHrs;
          TotalProductionQuantity =
            Number(item.summaryData[0]?.PartsProduced || 0) +
            TotalProductionQuantity;
          TotalRejectionQuantity =
            Number(item.summaryData[0]?.Rejected || 0) + TotalRejectionQuantity;
          RejectionPercentage =
            Number(item.summaryData[0]?.Rejected || 0) + RejectionPercentage;
          const totalPerformance = testdata.reduce((sum, item) => {
            return sum + Number(item.summaryData[0].OEE);
          }, 0);
          Performance = totalPerformance / testdata.length;

          item.DownTimeReasonWise.map((one) => {
            const existingIndex = DownTimeReason.findIndex(
              (reason) => reason === one.Reason
            );

            if (existingIndex !== -1) {
              // If the reason already exists, add the duration to the existing value
              DownTimeDuration[existingIndex] += Math.round(
                Number(one.Duration) / 3600
              );
            } else {
              // If the reason doesn't exist, push the new reason and its duration
              DownTimeReason.push(one.Reason || "Down");
              DownTimeDuration.push(Math.round(Number(one.Duration) / 3600));
            }
          });
          let RejectionReason = item.RejectionReason?.map((one) => {
            chartData.push({ name: one.Reason, values: [one.Count] });
          });
        });

        setDashboard(averageOEE);
        setGaugdata([
          {
            Shift: currentshift,
            TotalMachineRunningHrs: (TotalMachineRunningHrs / 60).toFixed(1),
            TotalMachineStopHrs: (TotalMachineStopHrs / 60).toFixed(1),
            TotalProductionQuantity: TotalProductionQuantity,
            TotalRejectionQuantity: TotalRejectionQuantity,
          },
        ]);

        setShiftTotal({
          TotalMachineRunningHrs: TotalMachineRunningHrs / 60,
          TotalMachineStopHrs: TotalMachineStopHrs / 60,
          TotalProductionQuantity,
          TotalRejectionQuantity,
          RejectionPercentage,
          // dayWiseLossHrs,
          Shift1Losshrs:
            currentshift === "Shift1"
              ? (TotalMachineStopHrs / 60).toFixed(1)
              : "",
          Shift2Losshrs:
            currentshift === "Shift2"
              ? (TotalMachineStopHrs / 60).toFixed(1)
              : "",
          Performance,
        });

        setTotalRejection({ chartData, Month: [currentshift] });

        setMonthlyDownTimeReason([
          {
            Tail: DownTimeDuration,
            Head: DownTimeReason,
            Month: [currentshift],
          },
        ]);

        setGaugeCartData([data1]);

        let MachinePerformance = {
          currentshift,
          Tail: PerformanceData1,
          Head: Equipment,
        };
        setPerformanceData([MachinePerformance]);
        setDayWiseOee({ Tail: [Performance.toFixed(1)], Head: [currentshift] });
        setDayLossHrs({
          Tail: [(totalLossHrs / 60).toFixed(1)],
          Head: [currentshift],
        });
        let MachineOee = { currentshift, Tail: OeeData1, Head: Equipment };
        // consol(MachineOee,"data in the array one by one ")

        setbardata([MachineOee]);
        setQuerySuccess(false);

        callFunctionRepeatedly(SubmitData, 600000);
      }

      // await axios.post(api.api1 +'getLiveDataForPlantReport',data).then((response) => {
      //   // consol(response.data,"live data recived from an api")
      // })
    } else {
      let eqg;
      if (type === "preload") {
        let equipmentdata = await storeDataInLocalStorage("equipment");
        const equiplist = equipmentdata
          .map((tool) => tool.EquipmentID)
          .slice()
          .sort((a, b) => a.localeCompare(b));
        eqg = equiplist;
        data["Component"] = ["all"];
        data["Operator"] = ["all"];
        data["Shift"] = ["all"];
      } else {
        eqg = selectedEquipOption.map((option) => option.label);
      }
      // const errors = validation.validate(data, schema);
      // // consol(errors)
      // setErrors(errors || {});
      // if (errors) return;
      let eqp = selectedEquipGroupOption.map((option) => option.label);
      data.Equipment = eqg;
      setQuerySuccess(true);
      console.log("current data for the APQ", data);
      await axios
        .post(api.api1 + "GetAPQLoss", data)
        .then((result) => {
          console.log(result.data, "current data for the APQ", data);

          setAPQdata(result.data);
        })
        .catch((error) => {});
      await axios
        .post(api.api1 + "PlantReport1", data)
        .then((result) => {
          // console.log(result.data,"vfkvbfivb")

          const data1 = [];
          const colorList = [
            { color1: "#3d87e6", color2: "#2a73ff" },
            { color1: "#ff9242", color2: "#ff8126" },
          ];
          result.data.SummaryData.map((item, index) => {
            return data1.push({
              title: item?.Shift,
              data: Number(item?.OEE),
              color1: colorList[index].color1,
              color2: colorList[index].color2,
            });
          });
          const initialValue = 0;
          const totalOEE = result.data.SummaryData.reduce((sum, item) => {
            return sum + Number(item.OEE);
          }, 0);

          // const monthlyOEE = result.data.monthlyOEE.reduce(
          //   (accumulator, currentValue) => accumulator + Number(currentValue.OEE),
          //   initialValue,
          // );
          setDashboard(totalOEE / result.data.SummaryData.length);

          let TotalMachineRunningHrs = 0;
          let TotalMachineStopHrs = 0;
          let TotalProductionQuantity = 0;
          let TotalRejectionQuantity = 0;
          let RejectionPercentage = 0;
          let Performance = 0;
          let Shift1Losshrs =
            result.data["SummaryData"][0]?.TotalMachineStopHrs;
          let Shift2Losshrs =
            result.data["SummaryData"][1]?.TotalMachineStopHrs;

          result.data["SummaryData"].forEach((element) => {
            TotalMachineRunningHrs =
              Number(element?.TotalMachineRunningHrs) + TotalMachineRunningHrs;
            TotalMachineStopHrs =
              Number(element?.TotalMachineStopHrs) + TotalMachineStopHrs;
            TotalProductionQuantity =
              Number(element?.TotalProductionQuantity) +
              TotalProductionQuantity;
            TotalRejectionQuantity =
              Number(element?.TotalRejectionQuantity) + TotalRejectionQuantity;
            RejectionPercentage =
              Number(element?.RejectionPercentage) + RejectionPercentage;
            Performance = Number(element?.Performance) + Performance;

            let OeeData1 = [];
            let OeeData2 = [];
            let EfficiencyData1 = [];
            let EfficiencyData2 = [];
            let PerformanceData1 = [];
            let PerformanceData2 = [];
            let AvailibilityData1 = [];
            let AvailibilityData2 = [];
            let QFData1 = [];
            let QFData2 = [];

            let currentshift = element.Shift;
            element["machineWiseTotalOEEShiftWise"].sort(
              (a, b) => a.Equipment - b.Equipment
            );
            element["machineWiseEfficiency"].sort(
              (a, b) => a.Equipment - b.Equipment
            );

            element["machineWiseEfficiency"].map((item) => {
              let Efficiency = item.Efficiency === "NaN" ? 0 : item.Efficiency;
              EfficiencyData1.push(Efficiency);
              EfficiencyData2.push(item.Equipment);
            });

            element["machineWiseTotalOEEShiftWise"].map((item) => {
              let OEE = item.OEE === "NaN" ? 0 : item.OEE;
              OeeData1.push(OEE);
              OeeData2.push(item.Equipment);
            });

            TotalData.push({ currentshift, Tail: OeeData1, Head: OeeData2 });
            TotalEfficiency.push({
              currentshift,
              Tail: EfficiencyData1,
              Head: EfficiencyData2,
            });
            element["machineWisePerformance"].sort(
              (a, b) => a.Equipment - b.Equipment
            );
            element["machineWisePerformance"].map((item) => {
              let Performance =
                item.Performance === "NaN" ? 0 : item.Performance;

              PerformanceData1.push(Performance);
              PerformanceData2.push(item.Equipment);
            });
            TotalData1.push({
              currentshift,
              Tail: PerformanceData1,
              Head: PerformanceData2,
            });
            element["machineWiseAvailability"].map((item) => {
              let Availability =
                item.Availability === "NaN" ? 0 : item.Availability;

              AvailibilityData1.push(Availability);
              AvailibilityData2.push(item.Equipment);
            });
            TotalData2.push({
              currentshift,
              Tail: AvailibilityData1,
              Head: AvailibilityData2,
            });
            TotalData1.push({
              currentshift,
              Tail: PerformanceData1,
              Head: PerformanceData2,
            });
            element["machineWiseQualityFactor"].forEach((item) => {
              let QF = isNaN(item.QF) ? 0 : item.QF;

              QFData1.push(QF);
              QFData2.push(item.Equipment);
            });

            TotalData3.push({ currentshift, Tail: QFData1, Head: QFData2 });
          });

          let OeeHead = [];
          let OeeData = [];
          result.data["dayWiseOEE"].map((item) => {
            OeeHead.push(moment(item.ShiftOf).format("DD-MMM-YY"));
            OeeData.push(item.OEE);
          });

          setDayWiseOee({ Tail: OeeData, Head: OeeHead });

          let LossHrsHead = [];
          let LossHrsData = [];
          result.data["dayWiseLossHrs"].map((item) => {
            LossHrsHead.push(moment(item.ShiftOf).format("DD-MMM-YY"));
            LossHrsData.push(item.LossHrs);
          });
          setDayLossHrs({ Tail: LossHrsData, Head: LossHrsHead });
          const uniqueReasons = [
            ...new Set(
              result.data["monthlyDownTimeAndRejection"].flatMap((entry) =>
                entry.RejectionReasonwise.map((reason) => reason.Reason)
              )
            ),
          ];
          let Month = [];
          const uniqueReasons1 = [
            ...new Set(
              result.data["monthlyDownTimeAndRejection"].flatMap((entry) =>
                entry.DownTimeReasonWise.map(
                  (reason) => reason.DowntimeReason || "Down"
                )
              )
            ),
          ];

          let header = Qualityreason.map((reason) => reason.QualityReason);
          let header2 = DownTimeReasons.map((reason) => reason.Reason);
          const chartData = uniqueReasons.map((reason) => {
            const values = result.data["monthlyDownTimeAndRejection"].map(
              (entry) => {
                const matchingReason = entry.RejectionReasonwise.find(
                  (r) => r.Reason === reason
                );
                return matchingReason ? matchingReason.Count : 0;
              }
            );

            return {
              name: reason,
              values,
            };
          });

          const chartData1 = uniqueReasons1.map((reason) => {
            const values = result.data["monthlyDownTimeAndRejection"].map(
              (entry) => {
                const matchingReason = entry.DownTimeReasonWise.find(
                  (r) => r.DowntimeReason === reason
                );
                return matchingReason ? Number(matchingReason.Duration) : 0;
              }
            );

            return {
              name: reason,
              values,
            };
          });

          result.data["monthlyDownTimeAndRejection"].map((item) => {
            Month.push(moment(item.From).format("MMMM, YYYY"));
            let ReasonHead = [];
            let ReasonData = [];
            item.DownTimeReasonWise.map((one) => {
              ReasonHead.push(one.DowntimeReason || "Down");
              ReasonData.push(Math.floor(one.Duration));
            });

            totalReasonData.push({ Tail: ReasonData, Head: ReasonHead, Month });
          });
          setShiftTotal({
            TotalMachineRunningHrs,
            TotalMachineStopHrs,
            TotalProductionQuantity,
            TotalRejectionQuantity,
            RejectionPercentage:
              RejectionPercentage / result.data["SummaryData"]?.length,
            dayWiseLossHrs: result.data.dayWiseLossHrs[0].LossHrs,
            Shift1Losshrs,
            Shift2Losshrs,
            Performance: Performance / result.data["SummaryData"]?.length,
          });

          setMonthlyDownTimeReason(totalReasonData);
          setTotalRejection({ chartData, Month });
          TotalData.sort((a, b) => {
            const shiftA = a.currentshift;
            const shiftB = b.currentshift;

            // Use localeCompare for string comparison
            return shiftA.localeCompare(shiftB);
          });
          if (TotalData.length > 1) {
            const Average = [];

            TotalData.map((item) => item.tail);
            for (let i = 0; i < TotalData[0].Head.length; i++) {
              Average.push(
                (
                  (Number(TotalData[0].Tail[i]) +
                    Number(TotalData[1].Tail[i])) /
                  2
                ).toFixed(1)
              );
            }
            TotalData.push({
              currentshift:
                "Avg " +
                TotalData[0].currentshift +
                " & " +
                TotalData[1].currentshift,
              Head: TotalData[0].Head,
              Tail: Average,
            });
          }

          TotalEfficiency.sort((a, b) => {
            const shiftA = a.currentshift;
            const shiftB = b.currentshift;

            // Use localeCompare for string comparison
            return shiftA.localeCompare(shiftB);
          });
          TotalData1.sort((a, b) => {
            const shiftA = a.currentshift;
            const shiftB = b.currentshift;

            // Use localeCompare for string comparison
            return shiftA.localeCompare(shiftB);
          });
          TotalData2.sort((a, b) => {
            const shiftA = a.currentshift;
            const shiftB = b.currentshift;

            // Use localeCompare for string comparison
            return shiftA.localeCompare(shiftB);
          });
          if (TotalEfficiency.length > 1) {
            const Average = [];

            TotalEfficiency.map((item) => item.tail);
            for (let i = 0; i < TotalEfficiency[0].Head.length; i++) {
              Average.push(
                (
                  (Number(TotalEfficiency[0].Tail[i]) +
                    Number(TotalEfficiency[1].Tail[i])) /
                  2
                ).toFixed(1)
              );
            }
            TotalEfficiency.push({
              currentshift:
                "Avg " +
                TotalEfficiency[0].currentshift +
                " & " +
                TotalEfficiency[1].currentshift,
              Head: TotalEfficiency[0].Head,
              Tail: Average,
            });
          }
          setbardata(TotalData);
          setEfficiency(TotalEfficiency);

          setPerformanceData(TotalData1);
          setAvailibilityData(TotalData2);
          setQfData(TotalData3);

          setGaugdata(result.data["SummaryData"]);
          data1.sort((a, b) => a.title.localeCompare(b.title));
          setGaugeCartData(data1);
          setQuerySuccess(false);
        })
        .catch((err) => {
          // consol(err);
          setQuerySuccess(false);
        });
    }
  };
  // fetchdata();
  // },[selectedDateRange])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleTripleClick = () => {
  //   tableRef.current += 1;

  //   if (tableRef.current === 3) {
  //     // Download the table data
  //     downloadTable();
  //     // Reset the click count
  //     tableRef.current = 0;
  //   }
  // };
  const downloadTable = (Gaugdata) => {
    // Assuming you have a function to get the table data
    const tableData = Gaugdata;

    // Convert the table data to a CSV format (customize as per your data structure)
    const csvContent = tableData
      .map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(",")
      )
      .join("\n");

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Use the file-saver library to save the Blob as a file
    saveAs(blob, "table_data.csv");
  };

  return (
    <>
      {querySuccess ? <Loading /> : ""}
      <div className="" style={{ width: "100%", height: "100%" }}>
        <div className="desktop-only text-center heading-bg mb-4">
          <h4 className="text-white p-0 m-0">Summary Report (1)</h4>
        </div>
        {/* only visible in the mobile screen */}
        <div className="mobile-only">
          <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary p-0 m-0">
              <div className="container-fluid text-center heading-bg">
                <h4 className="text-white m-auto p-0">Summary Report (1)</h4>
                <a className="navbar-brand" href="#"></a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <FontAwesomeIcon icon={faBars} size="lg" />
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarNavAltMarkup"
                >
                  <div className="navbar-nav">
                    <div className="col-sm-2 d-flex align-items-center  justify-content-center  text-center ">
                      <YourDateRangePickerComponent
                        onDateChange={handleDateChange}
                      />
                    </div>
                    {componentdata.length > 0 &&
                      DropDownData.map((data, index) => {
                        return (
                          <>
                            <div className="col-sm-2">
                              <div className="form-group text-center">
                                <label className="small">{data.heading}</label>

                                <>
                                  <ReusableCheckboxDropdown
                                    initialItems={data.initialItems}
                                    label={data.label}
                                    heading={`${
                                      selectedItemLengths[data.name] ==
                                      data.initialItems.length
                                        ? "All"
                                        : selectedItemLengths[data.name] || 0
                                    }  ${data.heading}`}
                                    selectedItemIds={data.selectedItemIds}
                                    onSelectedItemsChange={(newSelectedItems) =>
                                      // (newSelectedItems) for No. of item selected / current data name (data.name ) / (data.initialItems.length) is maximun length of list
                                      handleSelectedItemsChange(
                                        newSelectedItems,
                                        data.name,
                                        data.initialItems.length
                                      )
                                    }
                                    enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                                    multiSelect={data.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                                    searchbar={data.searchbar}
                                    selectAll={data.selectAll}
                                  />
                                </>

                                {errors[data.heading] && (
                                  <span
                                    className="text-danger "
                                    style={{ fontSize: 11 }}
                                  >
                                    {errors[data.heading]}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}

                    <div className="col-sm-2 d-flex align-items-center mt-2 p-0 mx-0">
                      <button
                        className=" mx-2 btn btn-primary "
                        onClick={() => {
                          setQuerySuccess(true);

                          SubmitData("");
                        }}
                      >
                        {" "}
                        Apply
                      </button>
                      <div className="uiverse mx-2" onClick={handlePrint}>
                        <span className="tooltip">Dowload pdf</span>
                        <span>Download</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="row m-2 " ref={targetRef}>
          <div className="desktop-only col-sm-2 d-flex align-items-center   justify-content-center  text-center ">
            <div className="desktop-only ">
              {" "}
              <YourDateRangePickerComponent onDateChange={handleDateChange} />
            </div>
          </div>
          {equipGroups.length > 0 && (
            <EquipmentSelection
              equipGroups={equipGroups}
              equip={equip}
              selectedEquipGroupOption={selectedEquipGroupOption}
              selectedEquipOption={selectedEquipOption}
              setSelection={setSelection}
            />
          )}

          {HideDropdown === false
            ? componentdata.length > 0 &&
              DropDownData.map((data, index) => {
                return (
                  <>
                    <div className={"col-sm-2 desktop-only"}>
                      <div className="form-group text-center">
                        <label className={"small "}>{data.heading}</label>

                        <>
                          <ReusableCheckboxDropdown
                            initialItems={data.initialItems}
                            label={data.label}
                            heading={`${
                              selectedItemLengths[data.name] ==
                              data.initialItems.length
                                ? "All"
                                : selectedItemLengths[data.name] || 0
                            }  ${data.heading}`}
                            selectedItemIds={data.selectedItemIds}
                            onSelectedItemsChange={(newSelectedItems) =>
                              // (newSelectedItems) for No. of item selected / current data name (data.name ) / (data.initialItems.length) is maximun length of list
                              handleSelectedItemsChange(
                                newSelectedItems,
                                data.name,
                                data.initialItems.length
                              )
                            }
                            enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                            multiSelect={data.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                            searchbar={data.searchbar}
                            selectll={data.selectAll}
                          />
                        </>

                        {errors[data.heading] && (
                          <span
                            className="text-danger "
                            style={{ fontSize: 11 }}
                          >
                            {errors[data.heading]}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })
            : componentdata.length > 0 &&
              DropDownData1.map((data, index) => {
                return (
                  <>
                    <div className={"col-sm-2 desktop-only"}>
                      <div className="form-group text-center">
                        <label className={"small "}>{data.heading}</label>

                        <>
                          <ReusableCheckboxDropdown
                            initialItems={data.initialItems}
                            label={data.label}
                            heading={`${
                              selectedItemLengths[data.name] ==
                              data.initialItems.length
                                ? "All"
                                : selectedItemLengths[data.name] || 0
                            }  ${data.heading}`}
                            selectedItemIds={data.selectedItemIds}
                            onSelectedItemsChange={(newSelectedItems) =>
                              // (newSelectedItems) for No. of item selected / current data name (data.name ) / (data.initialItems.length) is maximun length of list
                              handleSelectedItemsChange(
                                newSelectedItems,
                                data.name,
                                data.initialItems.length
                              )
                            }
                            enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                            multiSelect={data.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                            searchbar={data.searchbar}
                            selectAll={data.selectAll}
                          />
                        </>

                        {errors[data.heading] && (
                          <span
                            className="text-danger "
                            style={{ fontSize: 11 }}
                          >
                            {errors[data.heading]}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          <div className=" col-sm-2 d-flex align-items-center mt-2 p-0 mx-0">
            <button
              className=" desktop-only  mx-2 btn btn-primary "
              onClick={() => {
                setQuerySuccess(true);

                SubmitData();
              }}
            >
              {" "}
              Apply
            </button>
            <div className="desktop-only">
              <div className="uiverse mx-2  " onClick={handlePrint}>
                <span className="tooltip">Dowload pdf</span>
                <span>Download</span>
              </div>
            </div>
          </div>

          <div className="col-12 p-0 d-flex">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", margin: "auto" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  // centered={true}

                  scrollButtons="auto"
                >
                  <Tab label="OverView" {...a11yProps(0)} />
                  <Tab label="Machine OEE" {...a11yProps(1)} />
                  <Tab label="Machine Availability " {...a11yProps(2)} />
                  <Tab label="Machine Performance" {...a11yProps(3)} />
                  <Tab label="Machine Quality Factor " {...a11yProps(4)} />
                  <Tab label="Machine Efficiency" {...a11yProps(5)} />
                  <Tab label="Daily OEE / LOss Hrs" {...a11yProps(6)} />
                  <Tab label="mnth D/t by reasons " {...a11yProps(7)} />
                  <Tab label="mnth Rejection by reasons " {...a11yProps(8)} />
		              <Tab label="APQ " {...a11yProps(9)} />
                  <Tab label="Detail Report" {...a11yProps(10)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className="row">
                  {GaugeCartData.length > 0 ? (
                    GaugeCartData.map((item) => (
                      <div className="col-sm-4 p-0  ">
                        <GaugeChart
                          color1={item.color1}
                          color2={item.color2}
                          title={item.title}
                          data={item.data}
                        />
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="col-sm-4 p-0">
                        <GaugeChart
                          color1={"grey"}
                          color2={"grey"}
                          title={"No data"}
                          data={0}
                        />
                      </div>
                      <div className="col-sm-4 p-0">
                        <GaugeChart
                          color1={"grey"}
                          color2={"grey"}
                          title={"No data"}
                          data={0}
                        />
                      </div>
                    </>
                  )}

                  <div className="col-sm-4  p-0">
                    <div className="row text-white text-center mx-1">
                      <div
                        className="col-sm-4  p-0"
                        style={{ backgroundColor: "#bba3ff" }}
                      >
                        <h5>OEE %</h5>
                        <h3>{`${Number(Dashboard)?.toFixed(1)}`}</h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#f66cf4" }}
                      >
                        <h5>Machine Running (Hrs)</h5>
                        <h3>
                          {Number(ShiftTotal.TotalMachineRunningHrs)?.toFixed(
                            1
                          )}
                        </h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#bba3ff" }}
                      >
                        <h5>Production Qty </h5>
                        <h3 style={{ fontSize: "1.5vw" }}>
                          {ShiftTotal.TotalProductionQuantity}
                        </h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#f66cf4" }}
                      >
                        <h5>Performance %</h5>
                        <h3>{ShiftTotal.Performance?.toFixed(0)}</h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#bba3ff" }}
                      >
                        <h5>Rejection Qty</h5>
                        <h3>{ShiftTotal.TotalRejectionQuantity}</h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#f66cf4" }}
                      >
                        <h5>Rejection %</h5>
                        <h3>{ShiftTotal.RejectionPercentage?.toFixed(1)}</h3>
                      </div>

                      <div
                        className="col-sm-12 p-0 text-center"
                        style={{ backgroundColor: "#34b17f" }}
                      >
                        <h5>
                          <bold>Loss (IN Hours)</bold>
                        </h5>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#bba3ff" }}
                      >
                        <h5>Shift 1Loss (Hrs)</h5>
                        <h3>{Number(ShiftTotal.Shift1Losshrs)?.toFixed(1)}</h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#f66cf4" }}
                      >
                        <h5>Shift 2 Loss (Hrs)</h5>
                        <h3>{Number(ShiftTotal.Shift2Losshrs)?.toFixed(1)}</h3>
                      </div>
                      <div
                        className="col-sm-4 p-0"
                        style={{ backgroundColor: "#bba3ff" }}
                      >
                        <h5>Total Loss (Hrs)</h5>
                        <h3>
                          {Number(ShiftTotal.TotalMachineStopHrs)?.toFixed(1)}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 p-0">
                    <div
                      className="uiverse mx-2"
                      onClick={() => {
                        downloadTable(Gaugdata);
                      }}
                    >
                      <span className="tooltip">Dowload Excel</span>
                      <span>Download</span>
                    </div>
                    <div style={{ overflowX: "auto" }}>
                      <table style={{ width: "100%" }} className="styled-table">
                        <thead>
                          <tr>
                            <th>Shift</th>
                            <th>Total Runnig (hrs) </th>
                            <th>Total DownTime (hrs)</th>
                            <th>Total Production Qty</th>
                            <th>Total rejection Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{Gaugdata[0]?.Shift}</td>
                            <td>{Gaugdata[0]?.TotalMachineRunningHrs}</td>
                            <td>{Gaugdata[0]?.TotalMachineStopHrs}</td>
                            <td>{Gaugdata[0]?.TotalProductionQuantity}</td>
                            <td>{Gaugdata[0]?.TotalRejectionQuantity}</td>
                          </tr>
                          <tr className="active-row">
                            <td>{Gaugdata[1]?.Shift}</td>
                            <td>{Gaugdata[1]?.TotalMachineRunningHrs}</td>
                            <td>{Gaugdata[1]?.TotalMachineStopHrs}</td>
                            <td>{Gaugdata[1]?.TotalProductionQuantity}</td>
                            <td>{Gaugdata[1]?.TotalRejectionQuantity}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div style={{ overflowX: "auto" }}>
                      <table style={{ width: "100%" }} className="styled-table">
                        <thead>
                          <tr>
                            <th>Shift</th>
                            <th>Availability (%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{AvailibilityData[0]?.currentshift}</td>
                            <td>
                              {(
                                AvailibilityData[0]?.Tail.reduce(
                                  (acc, one) => acc + Number(one),
                                  0
                                ) / AvailibilityData[0]?.Tail.length
                              )?.toFixed(1)}
                            </td>
                          </tr>
                          <tr className="active-row">
                            <td>{AvailibilityData[1]?.currentshift}</td>
                            <td>
                              {(
                                AvailibilityData[1]?.Tail.reduce(
                                  (acc, one) => acc + Number(one),
                                  0
                                ) / AvailibilityData[1]?.Tail.length
                              )?.toFixed(1)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div className="row">
                  {/* <h4>Machine Wise OEE % (Shift A)</h4> */}
                  {bardata.length > 0 &&
                    bardata?.map((item, index) => (
                      <div className="col-12 p-0 text-center">
                        <MyChart
                          Barcolor={Barchartcolor[index]}
                          data={item}
                          height={"300px"}
                          title={`Machine Wise OEE % (${item.currentshift})`}
                          valuetype={"Percentage %"}
                        />
                      </div>
                    ))}
                  {/* <div className="col-12 p-0 text-center">
                    {bardata && (
                      <MyChart
                        Barcolor={"rgba(75, 175, 225, 0.8)"}
                        data={bardata[1]}
                        height={"300px"}
                        title={"Machine Wise OEE % (Shift B)"}
                        valuetype={"Percentage %"}
                      />
                    )}
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {AvailibilityData.length > 0 &&
                  AvailibilityData.map((item, index) => (
                    <div className="col-12 p-0 text-center">
                      <MyChart
                        Barcolor={Barchartcolor[index]}
                        data={item}
                        height={"300px"}
                        title={`Machine Wise Availibility % (${item.currentshift})`}
                        valuetype={"Percentage %"}
                      />
                    </div>
                  ))}
              </CustomTabPanel>

              <CustomTabPanel value={value} index={3}>
                {PerformanceData.length > 0 &&
                  PerformanceData.map((item, index) => (
                    <div className="col-12 p-0 text-center">
                      <MyChart
                        Barcolor={Barchartcolor[index]}
                        data={item}
                        height={"300px"}
                        title={`Machine Wise Performance % (${item.currentshift})`}
                        valuetype={"Percentage %"}
                      />
                    </div>
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                {QfData.length > 0 &&
                  QfData.map((item, index) => (
                    <div className="col-12 p-0 text-center">
                      <MyChart
                        Barcolor={Barchartcolor[index]}
                        data={item}
                        height={"300px"}
                        title={`Machine Wise QF % (${item.currentshift})`}
                        valuetype={"Percentage %"}
                      />
                    </div>
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                {Efficiency.length > 0 &&
                  Efficiency?.map((item, index) => (
                    <div className="col-12 p-0 text-center">
                      <MyChart
                        Barcolor={Barchartcolor[index]}
                        data={item}
                        height={"300px"}
                        title={`Machine Wise Efficiency % (${item.currentshift})`}
                        valuetype={"Percentage %"}
                      />
                    </div>
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={6}>
                <div className="col-12 p-0 text-center">
                  {/* <h4>Monthly Defect Wise Rejection Qty (Pcs)</h4>
<MyChartComponent  /> */}
                  <div className="col-12 p-0 text-center">
                    {/* <h4>Day Wise OEE % </h4> */}
                    {DayWiseOee && (
                      <MyChart
                        Barcolor={"rgba(255, 132, 72, 1)"}
                        data={DayWiseOee}
                        height={"300px"}
                        title={"Day Wise OEE % "}
                        valuetype={"Percentage %"}
                      />
                    )}
                  </div>
                  <div className="col-12 p-0 text-center">
                    {/* <h4>Day Wise Loss Hours (hrs)</h4> */}
                    {DayLossHrs && (
                      <MyChart
                        Barcolor={"rgba(75, 175, 225, 0.8)"}
                        data={DayLossHrs}
                        height={"300px"}
                        title={"Day Wise Loss Hours (hrs)"}
                        valuetype={"Hours"}
                      />
                    )}
                  </div>

                  {/* {PerformanceData &&(<MyChart Barcolor={'rgba(255, 132, 72, 1)'} data={PerformanceData[1]} />)} */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={7}>
                <div className="col-12 p-0 text-center">
                  {/* <h4>Monthly Defect Wise Rejection Qty (Pcs)</h4>
<MyChartComponent  /> */}
                  <div className="col-12 p-0 text-center">
                    {/* <h4> Monthly DownTime  (hrs) By DownTimeReason </h4> */}
                    {MonthlyDownTimeReason?.length > 0 &&
                      MonthlyDownTimeReason?.map((item, index) => {
                        return (
                          <MyChart
                            Barcolor={"rgba(255, 132, 72, 1)"}
                            data={item}
                            height={
                              MonthlyDownTimeReason.length > 1
                                ? "320px"
                                : "500px"
                            }
                            title={`${item.Month[index]} DownTime (hrs) By DownTimeReason `}
                            valuetype={"Hours"}
                          />
                        );
                      })}
                  </div>

                  {/* {PerformanceData &&(<MyChart Barcolor={'rgba(255, 132, 72, 1)'} data={PerformanceData[1]} />)} */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={8}>
                {" "}
                <div className="col-12 p-0 text-center">
                  {/* <h4>Monthly Rejection (Qty) By Reason</h4> */}
                  {TotalRejection?.chartData?.length > 0 ? (
                    <MyChartComponent
                      data={TotalRejection?.chartData}
                      Periodicity={TotalRejection?.Month}
                      title={"Monthly Rejection By Rejection Reason"}
                    />
                  ) : (
                    <div
                      style={{ width: "100%", height: "600px" }}
                      className="animated-gradient d-flex align-items-center justify-content-center"
                    >
                      <span className="text-center bolder">No Data ...</span>
                    </div>
                  )}

                  {TotalRejection?.chartData && (
                    <div style={{ overflowX: "auto" }}>
                      <table style={{ width: "100%" }} className="styled-table">
                        <thead>
                          <tr className="text-center">
                            <th>Month</th>
                            {TotalRejection.chartData?.map((item) => (
                              <th key={item.name}>{item?.name}</th>
                            ))}
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {TotalRejection?.Month.map((one, index) => (
                            <tr key={one}>
                              <td>{one}</td>
                              {TotalRejection?.chartData.map(
                                (item, innerIndex) => (
                                  <td key={innerIndex}>
                                    {item?.values[index]}
                                  </td>
                                )
                              )}
                              <td>
                                {TotalRejection?.chartData.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue.values[index],
                                  0
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </CustomTabPanel>
	          <CustomTabPanel value={value} index={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card className="shadow bg-danger bg-gradient">
                      <CardHeader
                        title="Availability"
                        subheader={
                          <Typography
                            variant="h2"
                            sx={{ my: 2, color: "black" }}
                          >
                            {APQdata.Cummulative &&
                              APQdata.Cummulative.Availability + "%"}
                          </Typography>
                        }
                        titleTypographyProps={{
                          sx: {
                            mb: 2.5,
                            fontSize: "2rem", // Increase the font size of the title

                            lineHeight: "2rem !important",
                            letterSpacing: "0.15px !important",
                          },
                        }}
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card className="shadow bg-success bg-gradient">
                      <CardHeader
                        title="Performance"
                        subheader={
                          <Typography
                            variant="h2"
                            sx={{ my: 2, color: "black" }}
                          >
                            {APQdata.Cummulative &&
                              APQdata.Cummulative.Performance + "%"}
                          </Typography>
                        }
                        titleTypographyProps={{
                          sx: {
                            fontSize: "2rem", // Increase the font size of the title
                            mb: 2.5,
                            fontSize: "2rem", // Increase the font size of the title

                            lineHeight: "2rem !important",
                            letterSpacing: "0.15px !important",
                          },
                        }}
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card className="shadow bg-secondary bg-gradient">
                      <CardHeader
                        title="Quality"
                        subheader={
                          <Typography
                            variant="h2"
                            sx={{ my: 2, color: "black" }}
                          >
                            {APQdata.Cummulative &&
                              APQdata.Cummulative.QualityFactor + "%"}
                          </Typography>
                        }
                        titleTypographyProps={{
                          sx: {
                            mb: 2.5,
                            fontSize: "2rem", // Increase the font size of the title

                            lineHeight: "2rem !important",
                            letterSpacing: "0.15px !important",
                          },
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card style={{ height: "500px" }} className="shadow">
                      <CardHeader
                        title="Availibility Loss"
                        titleTypographyProps={{
                          sx: {
                            lineHeight: "1.6 !important",
                            letterSpacing: "0.15px !important",
                          },
                        }}
                        action={
                          <IconButton
                            size="small"
                            aria-label="settings"
                            className="card-more-options"
                            sx={{ color: "text.secondary" }}
                          >
                            {/* <DotsVertical /> */}
                          </IconButton>
                        }
                      />
                      <CardContent
                        sx={{
                          pt: (theme) => `${theme.spacing(2.25)} !important`,
                          height: "410px",
                          overflow: "scroll",
                        }}
                      >
                        <Box
                          className="border"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                marginRight: 2,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "text.primary" }}
                              >
                                Downtime Reason
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                minWidth: 85,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "text.primary" }}
                              >
                                Availibility Loss
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {APQdata.Cummulative &&
                          APQdata.Cummulative.DowntimeReasons.map(
                            (item, index) => {
                              return (
                                <Box
                                  key={item.index}
                                  className="border"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        marginRight: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: "text.primary",
                                        }}
                                      >
                                        {item.DowntimeReason}
                                      </Typography>
                                      {/* <Typography variant='caption'>{item.subtitle}</Typography> */}
                                    </Box>

                                    <Box
                                      sx={{
                                        minWidth: 85,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: "text.primary",
                                        }}
                                      >
                                        {item.Availability}
                                      </Typography>
                                      {/* <LinearProgress color={item.color} value={item.progress} variant='determinate' /> */}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card
                      style={{ height: "500px", paddingBottom: "10px" }}
                      className="shadow"
                    >
                      <CardHeader
                        title="Performance Loss"
                        titleTypographyProps={{
                          sx: {
                            lineHeight: "1.6 !important",
                            letterSpacing: "0.15px !important",
                          },
                        }}
                        action={
                          <IconButton
                            size="small"
                            aria-label="settings"
                            className="card-more-options"
                            sx={{ color: "text.secondary" }}
                          >
                            {/* <DotsVertical /> */}
                          </IconButton>
                        }
                      />
                      <CardContent
                        sx={{
                          pt: (theme) => `${theme.spacing(2.25)} !important`,
                          height: "410px",
                          overflow: "scroll",
                        }}
                      >
                        <Box
                          className="border"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              margin: "10px 0px",
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                marginRight: 2,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "text.primary" }}
                              >
                                Machine Name
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                minWidth: 85,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "text.primary" }}
                              >
                                Performance Loss
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {APQdata.Cummulative &&
                          APQdata.Cummulative.EquipmentWisePerformanceLoss &&
                          APQdata.Cummulative.EquipmentWisePerformanceLoss.map(
                            (item, index) => {
                              return (
                                <Box
                                  key={item.index}
                                  className="border"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        marginRight: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: "text.primary",
                                        }}
                                      >
                                        {item.Equipment}
                                      </Typography>
                                      {/* <Typography variant='caption'>{item.subtitle}</Typography> */}
                                    </Box>

                                    <Box
                                      sx={{
                                        minWidth: 85,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: "text.primary",
                                        }}
                                      >
                                        {item.PerformanceLoss}
                                      </Typography>
                                      {/* <LinearProgress color={item.color} value={item.progress} variant='determinate' /> */}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card style={{ height: "500px" }} className="shadow">
                      <CardHeader
                        title="Quality Factor"
                        titleTypographyProps={{
                          sx: {
                            lineHeight: "1.6 !important",
                            letterSpacing: "0.15px !important",
                          },
                        }}
                        action={
                          <IconButton
                            size="small"
                            aria-label="settings"
                            className="card-more-options"
                            sx={{ color: "text.secondary" }}
                          >
                            {/* <DotsVertical /> */}
                          </IconButton>
                        }
                      />
                      <CardContent
                        sx={{
                          pt: (theme) => `${theme.spacing(2.25)} !important`,
                        }}
                      >
                        <Box
                          className="border"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                marginRight: 2,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "text.primary" }}
                              >
                                Rejection Reason
                              </Typography>
                            </Box>
                            {/* 
                <Box sx={{ minWidth: 85, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='body2' sx={{  fontWeight: 600, color: 'text.primary' }}>
                  Count
                  </Typography>
                </Box> */}
                            <Box
                              sx={{
                                minWidth: 85,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "text.primary" }}
                              >
                                Quality Factor
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {APQdata.Cummulative &&
                          APQdata.Cummulative.RejectionReasons.map(
                            (item, index) => {
                              return (
                                <Box
                                  key={item.index}
                                  className="border"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        marginRight: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: "text.primary",
                                        }}
                                      >
                                        {item.Reason}
                                      </Typography>
                                      {/* <Typography variant='caption'>{item.subtitle}</Typography> */}
                                    </Box>

                                    {/* <Box sx={{ minWidth: 85, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='body2' sx={{  fontWeight: 600, color: 'text.primary' }}>
                    {item.Count}
                  </Typography>
                </Box> */}
                                    <Box
                                      sx={{
                                        minWidth: 85,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: "text.primary",
                                        }}
                                      >
                                        {Number(item.QualityFactor).toFixed(5)}
                                      </Typography>
                                      {/* <LinearProgress color={item.color} value={item.progress} variant='determinate' /> */}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={10}>
                <SummaryReportTabReport
                  data={[]}
                  equips={equipList}
                  equipGroups={equipGroups}
                  reasons={reasons}
                  qualityReason={Qualityreason}
                />
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveDashBoards;
