import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import api from "../../config.json";
import axios from "../../httpServices/httpService";
import ProductionCountDashboardTable from "../../utill/productionCountDashboardTable";
import Loading from "../../utill/LoadingComponent";
import _ from "lodash";
import moment from "moment";

const EquipmentGroupHourlyCount = ({ equips }) => {
  const today = new Date();
  const tillDate = new Date(today);
  tillDate.setDate(today.getDate() - 7); // Subtract 7 days

  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [UpdateCount, setUpdateCount] = useState(0);

  
  console.log(widgets, "vbkdfgb", equips);
  useEffect(() => {
    
    const fetchData = async () => {
      setWidgets([]);
      const test =[]
console.log('34r43r34t3')

      // Create an array to hold the new widget data
      const newWidgets = [];

      for (const equip of equips) {
        setLoading(true); // Start loading before the API calls
        const widget = {
          Title: "7 Days AVG SPM",
          apiUrl: "GetCyclesHourly",
          inputData: {
            Equips: [equip],
            DateTime: today,
            Shift: "All",
            UserId: "62c67bc9654fcd38ba51a77c",
          },
        };

        try {
          const result = await axios.post(
            api.api + widget.apiUrl,
            widget.inputData
          );
          newWidgets.push(...result.data); // Add the received data to the newWidgets array

          // Update the state immediately with the new data
          setWidgets(newWidgets);
          setUpdateCount(1)
          setLoading(false); // Stop loading after all API calls are done
        } catch (error) {
          console.error(error, "API request error");
        }
      }
    };

    fetchData(); // Call the fetch function
  }, []); // Re-run the effect if equips change
  const handelGrouped = (data) => {
    const groupedData = _.groupBy(data, "EquipmentGroup");

    // Transform the grouped data to add EquipmentGroup to each group
    const transformedData = Object.keys(groupedData).map((key) => ({
      EquipmentGroup: key,
      EquipmentItems: groupedData[key],
    }));
    return transformedData;
  };

  useEffect(() => {
   
    const fetchData = async () => {
      setWidgets([]);
console.log('34r43r34t3')
      // Create an array to hold the new widget data
      const newWidgets = [];

      for (const equip of equips) {
        setLoading(true); // Start loading before the API calls
        const widget = {
          Title: "7 Days AVG SPM",
          apiUrl: "GetCyclesHourly",
          inputData: {
            Equips: [equip],
            DateTime: today,
            Shift: "All",
            UserId: "62c67bc9654fcd38ba51a77c",
          },
        };

        try {
          const result = await axios.post(
            api.api + widget.apiUrl,
            widget.inputData
          );
          newWidgets.push(...result.data); // Add the received data to the newWidgets array
          console.log(result, "first api call 2");
          // Update the state immediately with the new data
          setWidgets(newWidgets);
          setLoading(false); // Stop loading after all API calls are done
        } catch (error) {
          console.error(error, "API request error");
        }
      }
    };

    const calculateNextInterval = () => {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const milliseconds = now.getMilliseconds();
      const nextIntervalMinutes = (Math.ceil(minutes / 10) * 10) % 60;
      const delay =
        (nextIntervalMinutes - minutes) * 60 * 1000 -
        seconds * 1000 -
        milliseconds;
      return delay;
    };

    const timeout = setTimeout(() => {
      fetchData(); // Call fetchData at the next interval
      console.log("function runned");
      const interval = setInterval(fetchData, 600000);
      return () => clearInterval(interval);
    }, calculateNextInterval());

    return () => clearTimeout(timeout);
  }, []); // Add widgets as a dependency to refetch when they change

  return (
    <div
      style={{ width: "100%" }}
      className=""
    >
      {loading && <Loading />}

      <h1 className=" text-center">
        Live Hourly Count ({moment(today).format("D-MMM-YYYY")} /{" "}
        {widgets?.[0]?.Shift})
      </h1>

      {widgets.length > 0 ? (
        <ProductionCountDashboardTable
          data={handelGrouped(widgets)}
          className={""}
          rawData={widgets}
          dashBoard={true}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default EquipmentGroupHourlyCount;
