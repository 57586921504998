import React, { useState, useEffect } from "react";
import Table from "../Common/table";
import axios from "../httpServices/httpService";
import api from "../config";
import swal from "sweetalert";
import auth from "../httpServices/authService";
import validation from "../utill/validation";
import _ from "lodash";
import joi from "joi";
import Loading from "./../utill/LoadingComponent";
import date from "../utill/dateTime";
import EquipmentSelection from "../Common/equipmentSelection";
import * as XLSX from "xlsx";

const PressWellReport = ({
  handleDownloadTableToExcel,
  equips,
  equipGroups,
  reasons,
  qualityReason,
}) => {
  const [productionData, setProductionData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([]);
  const [index, setIndex] = useState(0);
  const [querySuccess, setQuerySuccess] = useState(false);
  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });

  const [Aggregate, setAggregate] = useState(false);
  const [TotalofDuration, setTotalofDuration] = useState([]);

  const schema = joi.object({
    From: joi.date().required().label("From Date"),
    UserId: joi.string(), // Optional by default
    Till: joi.date().required().label("Till Date"),
    Equipments: joi.array(), // Optional by default
    Shift: joi.array(), // Optional by default
    AggregateType: joi.string(), // Optional by default
  });
  const dataSheet1 = [
    { Name: "Alice", Age: 25 },
    { Name: "Bob", Age: 30 },
  ];

  const dataSheet2 = [
    { Product: "Laptop", Price: 1000 },
    { Product: "Phone", Price: 500 },
  ];

  const exportToExcel = () => {
    // Create a copy of the productionData and remove DowntimeReasons
    const Data = [...productionData];

    // Delete DowntimeReasons from each object in the Data array
    // Data.forEach(one => delete one.DowntimeReasons);

    // Create a workbook
    const workbook = XLSX.utils.book_new();

    // Convert each item in the Data array to a worksheet and add to the workbook
    Data.forEach((one, index) => {
      const sheet = XLSX.utils.json_to_sheet([one]); // wrap 'one' in an array
      XLSX.utils.book_append_sheet(workbook, sheet, "WorkSheet" + index);
    });

    // Write the workbook to a file
    XLSX.writeFile(workbook, "MultiSheetWorkbook.xlsx");
  };

  const handleOnChange = (e) => {
    if (e.currentTarget.type === "date") {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    } else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }
    setInputData(inputData);
    setErrors({});
  };
  const handleOnSubmit = async (inputData) => {
    productionData.splice(0, productionData.length);
    setIndex(0);

   
    const errors = validation.validate(inputData, schema);

    setErrors(errors || {});
    // if (errors) return;
    setQuerySuccess(true);
    const user = auth.getCurrentUser();
    inputData.UserId = user._id;
    inputData.Equipments = getEquipsForReport();

    // for (let i = 0; i < equipForReport.length; i++) {

    //let data=[...productionData];
    try {
      let result = await axios.post(api.api + "SPM_Report", inputData);

      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          result.data.forEach((one) => {
            productionData.push(one);
          });
          setProductionData(productionData);
          // setIndex(idx)
        }
        // else {
        //   setProductionData([])
        //   swal('Data Not Available');
        //   setQuerySuccess(false)
        // }
      } else {
        setQuerySuccess(false);
      }
    } catch (err) {
      swal(`${err}`);

      setQuerySuccess(false);
    }
    // }
    setQuerySuccess(false);
  };
  const getRowSpan = (data1, ReasonType) => {
    // return '3'

    let ReasonArr = [...data1.DowntimeReasons];
    let arr = [];
    let returnArr = [];
    for (let i = 0; i < ReasonArr.length; i++) {
      let arr1 = [];
      arr1.push(ReasonArr[i]);
      for (let j = i + 1; j < ReasonArr.length; j++) {
        if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
          arr1.push(ReasonArr[j]);
          ReasonArr.splice(j, 1);
          j--;
        }
      }
      arr.push(arr1);
    }
    arr.forEach((one) => {
      returnArr.push(one.length);
    });
    returnArr.sort(function (a, b) {
      return b - a;
    });
    if (returnArr.length > 0) {
      return returnArr[0];
    } else {
      return 1;
    }
  };

  const handleSort = (sortColumn) => {
    if (productionData.length > 0) {
      const sorted = _.orderBy(
        productionData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setProductionData(sorted);
      setSortColumn(sortColumn);
    }
  };

  const setSelection = (key, option, equips) => {
    if (key == "EquipmentGroup") {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option);
    }
    if (key == "Equipment") {
      setSelectedEquipOption(option);
    }
  };

  const getEquipsForReport = () => {
    let equipArr = [];
    equips.forEach((one) => {
      selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      });
    });
    return equipArr;
  };

  const getDowntimeReason = (downsReason, limit, ReasonType) => {
    let Total = [];
    let ReasonArr = [...downsReason];
    let arr = [];
    let returnArr = [];
    for (let i = 0; i < ReasonArr.length; i++) {
      let arr1 = [];
      arr1.push(ReasonArr[i]);
      for (let j = i + 1; j < ReasonArr.length; j++) {
        if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
          arr1.push(ReasonArr[j]);
          ReasonArr.splice(j, 1);
          j--;
        }
      }
      arr.push(arr1);
    }

    for (let j = 0; j < limit; j++) {
      if (j < limit) {
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i][j]) {
            arr2.push(arr[i][j]);
          }
        }
        returnArr.push(getDowntimeReason1(arr2, ReasonType));
      }
    }

    // arr.forEach((one,idx)=>{

    // })
    Total.push(returnArr);

    return returnArr;
  };

  const getDowntimeReason1 = (ReasonArr, Type) => {
    let returnArr = [];
    if ([...reasons.filter((one) => one.ReasonType === Type)].length == 0) {
      let obj = {};
      obj.Duration = null;
      obj.From = null;
      obj.Till = null;
      obj.Remarks = null;
      returnArr.push(obj);
    } else {
      [...reasons.filter((one) => one.ReasonType === Type)].forEach((one1) => {
        let filterReason = ReasonArr.filter(
          (obj) => obj.DowntimeReason == one1.Reason
        );
        if (filterReason.length > 0) {
          returnArr.push({ ...filterReason[0] });
        } else {
          let obj = {};
          obj.Duration = null;
          obj.From = null;
          obj.Till = null;
          obj.Remarks = null;
          returnArr.push(obj);
        }
      });
    }

    return returnArr;
  };
  function calculateDowntimeTotals(productionData, Type) {
    if (!productionData || productionData.length === 0) {
      return []; // Return empty array if no production data
    }

    // Calculate totals for each `one` in `productionData`
    const totals = productionData.map((one) => {
      const plannedDowntime = one.DowntimeReasons.filter(
        (reason) => reason.ReasonType === Type
      );

      const downtimeReasons = getDowntimeReason(
        plannedDowntime,
        getRowSpan(one, Type),
        Type
      );

      // Check if downtimeReasons is valid and calculate the total
      if (downtimeReasons.length > 0) {
        return downtimeReasons[0].map((_, index) =>
          downtimeReasons.reduce((sum, group) => sum + group[index].Duration, 0)
        );
      }

      return []; // Default value if there are no planned downtimes
    });

    // Calculate the overall total
    const result = totals[0]?.map((_, index) =>
      totals.reduce((sum, group) => sum + group[index], 0)
    );
    const overallTotal = calculateOverallTotal(totals);

    return { Total: result, overallTotal: overallTotal } || []; // Return overall result or empty array
  }

  function calculateOverallTotal(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return 0; // Return 0 if the data is empty or not an array
    }

    // Flatten the arrays and calculate the total sum
    const total = data.flat().reduce((sum, value) => sum + value, 0);
    return total;
  }

  const calculateTotalDuration = (data) => {
    return data.reduce((total, item) => total + item.Duration, 0);
  };
  return (
    <React.Fragment>
      {querySuccess ? (
        <Loading
        // labelShow={true}
        // label={`${index}/${getEquipsForReport().length}`}
        />
      ) : (
        ""
      )}
      <div className="text-center">
        <p>Presswell Report</p>
      </div>
      <div className="row">
        <EquipmentSelection
          equipGroups={equipGroups}
          equip={equip}
          selectedEquipGroupOption={selectedEquipGroupOption}
          selectedEquipOption={selectedEquipOption}
          setSelection={setSelection}
        />
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              className={"form-control form-control-sm"}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
            />
            {errors.From && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.From}
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              className="form-control form-control-sm"
              type="date"
              placeholder="dd/mm/yyyy"
              onChange={handleOnChange}
              name="Till"
            />
            {errors.Till && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Till}
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Shift</label>
            <select
              className="form-control form-control-sm"
              onChange={handleOnChange}
              name="Shift"
            >
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Shift}
              </p>
            )}
          </div>
        </div>
   
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit(inputData)}
          >
            Apply
          </button>
        </div>
        {productionData && productionData.length >0 && <div className="col-sm-1">
          <button className="btn btn-sm btn-primary" onClick={exportToExcel}>
            Export to Excel
          </button>
        </div>}
        <div
          className={productionData.length > 0 ? "col-sm-1" : "col-sm-1 d-none"}
        >
          <a
            className="btn btn-sm btn-primary "
            onClick={() => handleDownloadTableToExcel("Vg_PressShop")}
            style={{ float: "right", color: "white" }}
          >
            Download
          </a>
        </div>
      </div>
      <div>
        {/* {productionData.length > 0 ? <Table columns={ProductionReportSchema} onSort={handleSort} id='ProductionReport'  sortColumn={sortColumn} data={productionData} /> : <p></p>} */}

        {productionData.length > 0 ? (
          <table
            id="Vg_PressShop"
            className="table table-sm table-bordered table-hover small table-striped "
            style={{ padding: 0 }}
          >
            <thead
              style={{ top: 0, position: "sticky" }}
              className="small text-center border"
            >
              
               
              <tr className="text-center align-self-center table-primary">
                {inputData.AggregateType == "Equipment" ||
                inputData.AggregateType == "All" ||
                inputData.AggregateType == "EquipmentAndShift" ||
                inputData.AggregateType == "Shift" ? (
                  ""
                ) : (
                  <th style={{ verticalAlign: "middle" }} rowSpan="3">
                    Date
                  </th>
                )}
                {inputData.AggregateType == "All" ||
                inputData.AggregateType == "EquipmentAndShift" ? (
                  ""
                ) : (
                  <th colSpan={inputData.AggregateType == "All" ||inputData.AggregateType == "EquipmentAndShift" ? 2 :1} style={{ verticalAlign: "middle" }} rowSpan="3">
                    Equipment
                  </th>
                )}
                {inputData.AggregateType == "EquipmentAndShift" ||
                inputData.AggregateType == "All" ? (
                  ""
                ) : (
                  <th style={{ verticalAlign: "middle" }} rowSpan="3">
                    Shift
                  </th>
                )}
                <th  colSpan={inputData.AggregateType == "All" ? 2:1}style={{ verticalAlign: "middle" }} rowSpan="3">
                 RunTime (mins)
                </th>
              
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                Planned Downtime (mins)	
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                Unplanned Downtime (mins)
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                Production Qty.
                </th>
                <th style={{ verticalAlign: "middle" }} rowSpan="3">
                Running SPM	AVG SPM	
                </th> <th style={{ verticalAlign: "middle" }} rowSpan="3">
                Operator
                </th>
               
                {/* <th
                  className="table-primary"
                  style={{ verticalAlign: "middle" }}
                  colSpan={reasons.length + 2}
                >
                  STOPPAGE
                </th> */}
               
               
              </tr>
              {/* <tr className="table-primary">
                <th
                  colSpan={
                    reasons &&
                    [...reasons.filter((one) => one.ReasonType === "Planned")]
                      .length + 1
                  }
                >
                  PLANNED STOPPAGE
                </th>
                <th
                  colSpan={
                    reasons &&
                    [...reasons.filter((one) => one.ReasonType === "Unplanned")]
                      .length + 1
                  }
                >
                  UNPLANNED STOPPAGE
                </th>
              </tr>
              <tr className="table-primary">
                {[...reasons.filter((one) => one.ReasonType === "Planned")].map(
                  (one, index) => (
                    <th key={index}>{one.Reason}</th>
                  )
                )}
                <th>Total</th>
                {[
                  ...reasons.filter((one) => one.ReasonType === "Unplanned"),
                ].map((one, index) => (
                  <th key={index}>{one.Reason}</th>
                ))}
                <th>Total</th>
              </tr> */}
            </thead>
            <tbody>
              {productionData.map((one) => {
                return (
                  <>
                    <tr>
                      {inputData.AggregateType == "Equipment" ||
                      inputData.AggregateType == "All" ||
                      inputData.AggregateType == "EquipmentAndShift" ||
                      inputData.AggregateType == "Shift" ? (
                        ""
                      ) : (
                        <td>{date.getDateFormat(one.ShiftOf)}</td>
                      )}
                      {inputData.AggregateType == "All" ||
                      inputData.AggregateType == "EquipmentAndShift" ? (
                        ""
                      ) : (
                        <td>{one.Equipment}</td>
                      )}
                      {inputData.AggregateType == "EquipmentAndShift" ||
                      inputData.AggregateType == "All" ? (
                        ""
                      ) : (
                        <td>{one.Shift}</td>
                      )}
                      <td  colSpan={inputData.AggregateType == "All" ? 2:1}>{one.RunTime}</td>
                     
                      <td>{Number(one.PlannedDowntime).toFixed(0)}</td>
                      <td>{Number(one.UnplannedDowntime).toFixed(0)}</td>
                      <td>{one.PartsProduced}</td>

                      
                      <td>{one.RunningSPM}</td>
                      {/* <td>{one.AverageSPMRuntime}</td> */}
                      {/* <td>{Number(one.TotalDowntime).toFixed(0)}</td> */}
                      <td>{one.Operator}</td>
                      {/* <td>{one.AverageSPMWithDowntime}</td>
                      <td>{one.AverageSPMWithPlanned}</td>
                      <td>{one.AverageSPMWithBreaks}</td> */}
                      {/* {getDowntimeReason(
                        [
                          ...one.DowntimeReasons.filter(
                            (one) => one.ReasonType === "Planned"
                          ),
                        ],
                        getRowSpan(one, "Planned"),
                        "Planned"
                      ).length > 0
                        ? getDowntimeReason(
                            [
                              ...one.DowntimeReasons.filter(
                                (one) => one.ReasonType === "Planned"
                              ),
                            ],
                            getRowSpan(one, "Planned"),
                            "Planned"
                          ).map((o, i) => {
                            if (i == 0) {
                              return o.map((r) => {
                                return (
                                  <>
                                    <td>{r.Duration ? r.Duration : 0}</td>
                                  </>
                                );
                              });
                            }
                          })
                        : ""}
                      <td>
                        {calculateTotalDuration([
                          ...one.DowntimeReasons.filter(
                            (one) => one.ReasonType === "Planned"
                          ),
                        ])}
                      </td>
                      {getDowntimeReason(
                        [
                          ...one.DowntimeReasons.filter(
                            (one) => one.ReasonType === "Unplanned"
                          ),
                        ],
                        getRowSpan(one, "Unplanned"),
                        "Unplanned"
                      ).length > 0
                        ? getDowntimeReason(
                            [
                              ...one.DowntimeReasons.filter(
                                (one) => one.ReasonType === "Unplanned"
                              ),
                            ],
                            getRowSpan(one, "Unplanned"),
                            "Unplanned"
                          ).map((o, i) => {
                            if (i == 0) {
                              return o.map((r) => {
                                return (
                                  <>
                                    <td>{r.Duration ? r.Duration : 0}</td>
                                  </>
                                );
                              });
                            }
                          })
                        : ""}
                      <td>
                        {Number(
                          calculateTotalDuration([
                            ...one.DowntimeReasons.filter(
                              (one) => one.ReasonType === "Unplanned"
                            ),
                          ])
                        ).toFixed(1)}
                      </td> */}
                     
                    </tr>
                  </>
                );
              })}
              {/* <tr className="font-weight-bold">
                <td colSpan={inputData.AggregateType == "Equipment" ? 2:
                inputData.AggregateType == "All" ? 1 :
                inputData.AggregateType == "EquipmentAndShift" ? 1 :
                inputData.AggregateType =="Shift" ? 2:3}>
                  Total
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + one.TotalTime, 0)).toFixed(0)}
                </td>
                <td>
                  {productionData
                    .reduce((sum, one) => sum + Number(one.LoadingTime), 0)
                    .toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce(
                    (sum, one) => sum + one.TotalStrokes,
                    0
                  ).toFixed(0))}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + one.RejStrokes, 0)).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + one.AvgSPM, 0)).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + one.RunningSPM, 0) ).toFixed(0)}
                </td>
                <td>
                  {(productionData.reduce((sum, one) => sum + one.TargetSPM, 0)).toFixed(0)}
                </td>
                <td>
                  {((productionData.reduce(
                    (sum, one) => sum + one.Performance,
                    0
                  ))/productionData.length).toFixed(1)}
                </td>
                <td>
                  {((productionData.reduce(
                    (sum, one) => sum + one.Availability,
                    0
                  ))/productionData.length).toFixed(1)}
                </td>
                <td>
                  {((productionData.reduce(
                    (sum, one) => sum + one.QualityFactor,
                    0
                  ))/productionData.length).toFixed(1)}
                </td>
                <td>{((productionData.reduce((sum, one) => sum + one.OEE, 0))/productionData.length).toFixed(1)}</td>

                

                {calculateDowntimeTotals(productionData, "Planned") &&
                  calculateDowntimeTotals(productionData, "Planned").Total &&
                  calculateDowntimeTotals(productionData, "Planned").Total.map(
                    (Duration) => <td>{(Number(Duration)).toFixed(0)}</td>
                  )}

                {calculateDowntimeTotals(productionData, "Planned") &&
                  calculateDowntimeTotals(productionData, "Planned")
                    .overallTotal && (
                    <td>
                      {
                        calculateDowntimeTotals(productionData, "Planned")
                          .overallTotal
                      }
                    </td>
                  )}

                {calculateDowntimeTotals(productionData, "Unplanned") &&
                  calculateDowntimeTotals(productionData, "Unplanned").Total &&
                  calculateDowntimeTotals(
                    productionData,
                    "Unplanned"
                  ).Total.map((Duration) => <td>{(Number(Duration)).toFixed(0)}</td>)}
                {calculateDowntimeTotals(productionData, "Unplanned") &&
                  calculateDowntimeTotals(productionData, "Unplanned")
                    .overallTotal && (
                    <td>
                      {
                        calculateDowntimeTotals(productionData, "Unplanned")
                          .overallTotal
                      }
                    </td>
                  )}

                <td>
                  {productionData
                    .reduce((sum, one) => sum + Number(one.Downtime), 0)
                    .toFixed(1)}
                </td>
                <td>
                  {productionData
                    .reduce((sum, one) => sum + Number(one.LoadingTime), 0)
                    .toFixed(1)}
                </td>
                <td>
                  {productionData
                    .reduce((sum, one) => sum + Number(one.RunningTime), 0)
                    .toFixed(1)}
                </td>
                <td>-</td>
                <td>
                  {productionData.reduce((sum, one) => sum + one.MC_Count, 0)}
                </td>
                <td>
                  {" "}
                  {Number(
                    productionData.reduce((sum, one) => sum + one.MTTR, 0)
                  ).toFixed(1)}
                </td>
                <td>
                  {" "}
                  {Number(
                    productionData.reduce((sum, one) => sum + one.MTBF, 0)
                  ).toFixed(1)}
                </td>

                <td>
                  {productionData.reduce(
                    (sum, one) => sum + one.ToolMC_Count,
                    0
                  )}
                </td>
                <td>
                  {" "}
                  {Number(
                    productionData.reduce((sum, one) => sum + one.Tool_MTTR, 0)
                  ).toFixed(1)}
                </td>
                <td>
                  {" "}
                  {Number(
                    productionData.reduce((sum, one) => sum + one.Tool_MTBF, 0)
                  ).toFixed(1)}
                </td>
              </tr> */}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};
export default PressWellReport;
