import React, { Component } from "react";
import MultiRadialChart from "../../utill/MultiRadialChart";
import PieChart from "../../utill/PieChartAVEP";
import MachineDetailedPage from "../../utill/MachineDetailedPage";
import api from '../../config.json';
import BatchSize from '../../config.json';

import axios from '../../httpServices/httpService';
import auth from '../../httpServices/authService';
import swal from 'sweetalert';
import './dashboardV1.1.css';
import date from '../../utill/dateTime';
import Loading from "../../utill/LoadingComponent";
import pako from 'pako'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Button, Tooltip, ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import './OperatorPerfBoard.css';
import moment from "moment";
import { cloneDeep } from "lodash";

class OptPerformanceBoard extends Component {

    state = {
        array: [],
        modelState: false,
        singleMachineData: [[]],
        singleMachineData1: [[
            "Time(In Minutes)",
            "Device Power",
            { role: "style" },
            { role: "annotation" },
            // "Device Status",
            // {role:"annotation"},

        ],],
        singleMachineAllData: {},
        querySuccess: false,
        selectedCard: {},
        loading: false,
        filterObj: {},
        jobTableData: [],
        originalData: [],
        Equips: [],
        filterBy: {},
        autoScrolObj: {},
        PowerDown: false,
        countIntrvl: 0
    }

    MyInterval;


    ScreensInterval;

    getScrollData = (data) => {
        let scrollObj = localStorage.getItem('op_per_board_state')
        if (scrollObj) {
            let obj = JSON.parse(localStorage.getItem('op_per_board_state'));
            if (obj.AutoScroll) {
                data = [...data]
                if (obj.DisplayFor && (obj.DisplayFor !== "") && obj.NumberOfMachine && (obj.NumberOfMachine !== "")) {
                    let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
                    if ((endIndex > (data.length - 1))) {
                        data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                        localStorage.setItem("startIndex", 0);
                    } else {
                        data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                        localStorage.setItem("startIndex", endIndex);
                    }

                }
            }

        }
        return data;
    }

    displayFor = () => {
        let autoScrollObj = localStorage.getItem("op_per_board_state");
        if (autoScrollObj) {
            autoScrollObj = JSON.parse(autoScrollObj);
            if (autoScrollObj.DisplayFor && (autoScrollObj.DisplayFor != "") && autoScrollObj.NumberOfMachine && (autoScrollObj.NumberOfMachine != "")) {
                this.ScreensInterval = setInterval(() => {
                    let obj = JSON.parse(localStorage.getItem('op_per_board_state'));
                    if (obj.AutoScroll) {
                        let data = cloneDeep(this.state.originalData)
                        if (obj.DisplayFor && (obj.DisplayFor !== "")) {
                            let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
                            if ((endIndex > (data.length - 1))) {
                                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                                localStorage.setItem("startIndex", 0);
                            } else {
                                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                                localStorage.setItem("startIndex", endIndex);
                            }

                        } else {
                            data = data.slice(0, parseInt(autoScrollObj.NumberOfMachine))
                        }
                        // data = this.getFilterData(data, this.props.filterObj)
                        this.setState({ countIntrvl: 1, array: data, autoScrolObj: JSON.parse(localStorage.getItem("op_per_board_state")) }, () => {
                            if ((JSON.stringify(this.state.autoScrolObj) !== localStorage.getItem('op_per_board_state'))) {
                                this.ScreensInterval = null;
                                clearInterval(this.ScreensInterval);
                                this.displayFor();
                            }
                        });
                    } else {
                        this.ScreensInterval = null;
                        clearInterval(this.ScreensInterval);

                        this.setState({ array: this.state.originalData })
                    }

                }, (parseInt(autoScrollObj.DisplayFor) * 1000))
            }
        }





    }

    async componentDidMount() {
        localStorage.setItem("startIndex", 0);
        let equips = []
        /////////////////////////////////////////////// Change batchSize to vary the amount of equipment send through Api /////////////////////////////////////
        const batchSize = Number(BatchSize.BatchSize);

        if (this.props.user) {
            const storedEquipGroupsData = localStorage.getItem('equipment');     /// getting equipments from local storage , the equipments are stored in machine state dashboard by calling api
            if (storedEquipGroupsData) {
                // Data exists in local storage, use it
                const storedEquipGroups = JSON.parse(storedEquipGroupsData);
                equips = await storedEquipGroups

                this.setState({ querySuccess: false });
            }
            // equips=equips.filter(one=>one.EquipmentID=='bm-03')
            this.setState({ querySuccess: true, loading: true });


            for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
                let startIndex = i * batchSize;
                let endIndex = (i + 1) * batchSize;
                let senddata = equips.slice(startIndex, endIndex)
                ////////////////// compressed data with pako ///////////////
                // const compressedData = pako.gzip(JSON.stringify({ UserId: this.props.user._id, Equips: senddata }));
                try {
                    // this.setState({ querySuccess: true,loading:true });
                    // let res=await axios.put(api.api1 + 'GetCardViewDashboardForLiveView', { UserId: this.props.user._id,Equips:[equips.data[i]] })

                    ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
                    let res = await axios
                        .put(api.api1 + "GetLiveOverviewDashboard", { UserId: this.props.user._id, Equips: senddata })

                    /////////////////////////// de compressing the recived result  data ////////////

                    //  const decompressedData = pako.ungzip(res.data, { to: 'string' })

                    ///////////////////////////// conevrting sting into json ///////////
                    const jsonData = res.data//JSON.parse(decompressedData)


                    let array = [...this.state.array];
                    array = [...array, ...jsonData];
                    let originalData = cloneDeep(array)
                    //array = this.getFilterData(array, this.props.filterObj)
                    this.setState({ array: array, originalData: originalData, filterBy: this.props.filterBy, filterObj: this.props.filterObj });
                }
                catch (err) {
                    this.setState({ querySuccess: false, loading: false })
                    if (err.response && err.response.data && (err.response.data.code == 901)) {
                        swal(`${err.response.data.message}`)
                    } else {
                        swal(`${err}`)
                    }

                }
            }
            this.setState({ querySuccess: false, loading: false, Equips: equips.data })

        }
        this.MyInterval = setInterval(async () => {
            if (this.props.user) {

                const storedEquipGroupsData = localStorage.getItem('equipment');     /// getting equipments from local storage , the equipments are stored in machine state dashboard by calling api
                if (storedEquipGroupsData) {
                    // Data exists in local storage, use it
                    const storedEquipGroups = JSON.parse(storedEquipGroupsData);
                    equips = await storedEquipGroups
                    // equips=equips.filter(one=>one.EquipmentID=='bm-03')

                    this.setState({ querySuccess: false });
                }
                // let equips= await axios.get(api.api1 + 'equipment');

                let array = cloneDeep(this.state.originalData);

                for (let i = 0; i < Math.ceil(equips?.length / batchSize); i++) {
                    let startIndex = i * batchSize;
                    let endIndex = (i + 1) * batchSize;
                    let senddata = equips.slice(startIndex, endIndex)
                    ////////////////// compressed data with pako ///////////////
                    //const compressedData = pako.gzip(JSON.stringify({ UserId: this.props.user._id, Equips: senddata }));
                    try {
                        // this.setState({ querySuccess: true,loading:true });
                        // let res=await axios.put(api.api1 + 'GetCardViewDashboardForLiveView', { UserId: this.props.user._id,Equips:[equips.data[i]] })
                        ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
                        let res = await axios
                            .put(api.api1 + "GetLiveOverviewDashboard", { UserId: this.props.user._id, Equips: senddata })

                        /////////////////////////// de compressing the recived result  data ////////////

                        // const decompressedData = pako.ungzip(res.data, { to: 'string' })

                        ///////////////////////////// conevrting sting into json ///////////
                        res.data.forEach((jsonData) => {
                            const obj = cloneDeep(jsonData);
                            const findEquipIdx = array.findIndex(x => x.Equipment == obj.Equipment);
                            if (findEquipIdx > -1)
                                array[findEquipIdx] = obj;
                        })

                    }
                    catch (err) {
                        this.setState({ querySuccess: false, loading: false })
                        if (err.response && err.response.data && (err.response.data.code == 901)) {
                            swal(`${err.response.data.message}`)
                        } else {
                            swal(`${err}`)
                        }
                    }
                }

                let originalData = cloneDeep(array)
                // array = this.getFilterData(array, this.props.filterObj)
                this.setState({ array: this.getScrollData(originalData), originalData: originalData, filterBy: this.props.filterBy, filterObj: this.props.filterObj });

                // axios.put(api.api1 + 'GetCardViewDashboardForLiveView', { UserId: this.props.user._id,Equips:this.state.Equips })
                //   .then((result) => {
                //     let originalData = [...result.data]
                //     result = this.getFilterData(result.data, this.props.filterObj)
                //     this.setState({ array: result, originalData: originalData, filterObj: this.props.filterObj, querySuccess: false });
                //   })
                //   .catch(async (err) => {
                //     this.setState({ querySuccess: false })
                //     if (err.response) {
                //       swal(`${err.response.data.message}`);
                //     } else {
                //       swal(`${err}`);
                //     }
                //   })
            }
        }, 5 * 60 * 1000)
        this.displayFor()

    }

    componentDidUpdate() {
        if (!this.ScreensInterval && this.props.autoScrollObj && this.props.autoScrollObj.AutoScroll && this.state.countIntrvl == 0) {
            this.displayFor()
        }
    }

    componentWillUnmount() {
        clearInterval(this.MyInterval)
        clearInterval(this.ScreensInterval)
    }

    getFilterData = (data, filterKey) => {
        let arr = [...data];
        data = []
        Object.keys(this.props.filterBy).forEach((key) => {
            let filterData = arr.filter(one => ((one.EquipmentGroup == key) && (this.props.filterBy[key])))
            data = [...data, ...filterData];
        })
        if (filterKey.SortBy == 'Descending') {
            data = data.sort(function (a, b) {
                if (a.Equipment < b.Equipment) {
                    return 1;
                }
                else if (a.Equipment > b.Equipment) {
                    return -1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'Ascending') {
            data = data.sort(function (a, b) {
                if (a.Equipment < b.Equipment) {
                    return -1;
                }
                else if (a.Equipment > b.Equipment) {
                    return 1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'Running On Top') {
            data = data.sort(function (a, b) {
                if (a.State == 'Running') {
                    return -1;
                }
                else if (a.State !== 'Running') {
                    return 1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'Not Running On Top') {
            data = data.sort(function (a, b) {
                if (a.State !== 'Running') {
                    return -1;
                }
                else if (a.State == 'Running') {
                    return 1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'OEE Descending') {
            data = data.sort(function (a, b) {
                if (a.OEE < b.OEE) {
                    return 1;
                }
                else if (a.OEE > b.OEE) {
                    return -1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'OEE Ascending') {
            data = data.sort(function (a, b) {
                if (a.OEE < b.OEE) {
                    return -1;
                }
                else if (a.OEE > b.OEE) {
                    return 1;
                }
                return 0;
            });
        }

        if (filterKey.SortBy == 'Availability Descending') {
            data = data.sort(function (a, b) {
                if (a.Availability < b.Availability) {
                    return 1;
                }
                else if (a.Availability > b.Availability) {
                    return -1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'Availability Ascending') {
            data = data.sort(function (a, b) {
                if (a.Availability < b.Availability) {
                    return -1;
                }
                else if (a.Availability > b.Availability) {
                    return 1;
                }
                return 0;
            });
        }

        if (filterKey.SortBy == 'Performance Descending') {
            data = data.sort(function (a, b) {
                if (a.Performance < b.Performance) {
                    return 1;
                }
                else if (a.Performance > b.Performance) {
                    return -1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'Performance Ascending') {
            data = data.sort(function (a, b) {
                if (a.Performance < b.Performance) {
                    return -1;
                }
                else if (a.Performance > b.Performance) {
                    return 1;
                }
                return 0;
            });
        }

        if (filterKey.SortBy == 'Quality Descending') {
            data = data.sort(function (a, b) {
                if (a.Quality < b.Quality) {
                    return 1;
                }
                else if (a.Quality > b.Quality) {
                    return -1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'Quality Ascending') {
            data = data.sort(function (a, b) {
                if (a.Quality < b.Quality) {
                    return -1;
                }
                else if (a.Quality > b.Quality) {
                    return 1;
                }
                return 0;
            });
        }

        return data
    }


    onHide = () => {
        this.setState({ selectedCard: {}, modelState: false, singleMachineData: [["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }, "next diffrence", { role: "style" }]] });
    }

    // componentDidUpdate(){
    //   if(this.state.filterObj.FilterBy!==this.props.filterObj.FilterBy){
    //     let data=this.getFilterData(this.state.array,this.props.filterObj);
    //     this.setState({array:data})
    //   }
    //   if(this.state.filterObj.SortBy!==this.props.filterObj.SortBy){
    //     let data=this.getFilterData(this.state.array,this.props.filterObj);
    //     this.setState({array:data})
    //   }

    // }

    getColorForCarHeader = (one) => {
        let obj = {}
        if (one.State == 'Running') {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#0F9D58', padding: 0 }
            }

        }
        else if (one.State == 'Down') {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#DB4437', padding: 0 }
            }

        }
        else if (one.State == 'Break') {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#F4B400', padding: 0 }
            }
        }
        else {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#DB4437', padding: 0 }
            }
        }
        return obj;
    }





    render() {
        // if (this.state.loading ){
        //   return <Loading/>
        // }
        // console.log(this.state.singleMachineData1,"vfsv")
        return (
            <>
                {this.state.loading ? <Loading labelShow={true} label={`${this.state.array.length}/${this.props.equipList.length}`} /> : ''}
                <div style={{
                    backgroundColor: "#aee357",
                    padding: '1%'
                }} className="row">
                    <div className="col-sm-7">
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'darkred' }}>Operator Performance Board</span>
                    </div>
                    <div className="col-sm-3"  style={{ textAlign: 'right'}}>
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'darkred' }}>Date : {this.state.array.filter(x => x).length > 0 ? moment(this.state.array[0].From).format('DD-MM-YYYY') : '-'}</span>
                    </div>
                    <div className="col-sm-2" style={{ textAlign: 'right'}}>
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'darkred' }}>Shift : {this.state.array.filter(x => x).length > 0 ? this.state.array[0].Shift : '-'}</span>
                    </div>
                </div>
                <div

                    className="table-responsive-sm table-striped">
                    <table
                        //  style={{
                        //     height: 'calc(100vh - 120px)', // Adjust based on header height
                        //     overflowY: 'auto', // Add scroll if content overflows
                        // }}
                        className="table table-sm table-bordered custom-table-op custom-table">
                        <thead>
                            <tr className="table-primary">
                                <th>M/C</th>
                                <th>OPTR.</th>
                                <th>PART</th>
                                <th>TGT</th>
                                <th>ACT</th>
                                <th>REJ</th>
                                <th>DT.(In Mins)</th>
                                <th>%AC</th>
                                <th>%RJ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cloneDeep(this.state.array).map((one, idx) => {
                                return one && (
                                    <tr className="table-success" key={idx.toString()}>
                                        <td>{one.Equipment}</td>
                                        <td>{one.Operators}</td>
                                        <td>{one.Component}</td>
                                        <td>{one.TargetParts}</td>
                                        <td>{one.ActualParts}</td>
                                        <td>{one.Rejected}</td>
                                        <td>{one.DowntimeDuration}</td>
                                        <td>{one.Acheived}</td>
                                        <td>{one.QualityFactor}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </>
        );
    }
}

export default OptPerformanceBoard;
