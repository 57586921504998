import React, { Component } from "react";
import Table from "../../../Common/table";
//import "../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import dateTime from "../../../utill/dateTime";
import swal from "sweetalert";
import api from "../../../config";
import axios from "../../../httpServices/httpService";
import _ from "lodash";
import UserRights from "./UserRight";
import auth from "../../../httpServices/authService";
import Loading from "../../../utill/LoadingComponent";
import { ToastContainer, toast } from 'react-toastify';

class UserRightTable extends Component {
  state = {
    userList: [],
    userRights: [],
    data: { dataEntryPoints: [], reports: [], notifications: [] },
    collapseStatus: {},
    querySuccess: false,
    modalShow: false,
    updateUser: null,
    updateUserRights: null,
  };

  componentDidMount() {
    this.tableRef = React.createRef();
    //const  user  = auth.getCurrentUser();
    this.setState({ querySuccess: true });


    // let userList = axios.get(api.api + "user");
    // let dataList=axios.put(api.api+'userrights')

    // Promise.all([dataList, userList])
    //   .then((result) => {
    //     // consol(result);

    //     this.setState({
    //       dataList: result[0].data,
    //       userList: result[1].data,
    //       querySuccess:false
    //     });
    //   })
    axios.get(api.api + "user").then((result) => {
      this.setState({

        userList: result.data,
        querySuccess: false
      });
    })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  }
  handleOnChange = (e) => {
    const { data } = { ...this.state };

    const { id, name, value } = e.currentTarget;
    if (id === "DataEntryPoint" && value === "Admin") {
      data.dataEntryPoints = data.dataEntryPoints.filter(one => one.dataEntryPoint !== name.split('|')[0])
      let obj = { dataEntryPoint: name.split('|')[0], role: value }
      // // consol(obj)
      data.dataEntryPoints.push(obj);
    }
    if (id === "DataEntryPoint" && value === "User") {
      data.dataEntryPoints = data.dataEntryPoints.filter(one => one.dataEntryPoint !== name.split('|')[0])

      let obj = { dataEntryPoint: name.split('|')[0], role: value }
      // // consol(obj)
      data.dataEntryPoints.push(obj);
    }
    if (id === "DataEntryPoint" && value === "No") {
      data.dataEntryPoints = data.dataEntryPoints.filter(one => one.dataEntryPoint !== name.split('|')[0])
    }
    if (id === "Report" && value === "true") {
      data.reports = data.reports.filter(one => one.report !== name.split('|')[0])

      let obj = { report: name.split('|')[0], role: value }
      data.reports.push(obj);
    }
    if (id === "Report" && value === "false") {
      data.reports = data.reports.filter(one => one.report !== name.split('|')[0])

      let obj = { report: name.split('|')[0], role: value }
      data.reports.push(obj);
    }
    if (id === "Report" && value === "No") {
      data.reports = data.reports.filter(one => one.report !== name.split('|')[0])
    }
    if (id === "Notification" && value === "true") {
      data.notifications = data.notifications.filter(one => one.notification !== name.split('|')[0])

      let obj = { notification: name.split('|')[0], role: value }
      data.notifications.push(obj);
    }
    if (id === "Notification" && value === "false") {
      data.notifications = data.notifications.filter(one => one.notification !== name.split('|')[0])

      let obj = { notification: name.split('|')[0], role: value }
      data.notifications.push(obj);
    }
    if (id === "Notification" && value === "No") {
      data.notifications = data.notifications.filter(one => one.notification !== name.split('|')[0])
    }

    this.setState({ data });
    // // consol(data);
  };
  handleClickEdit = (obj) => {
    axios.put(api.api + 'userrights', { email: obj._id }).then((result) => {
      this.setState({ modalShow: true, updateUser: obj, updateUserRights: result.data });
    })
      .catch((err) => {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })

  }
  handleClose = () => {
    this.setState({ modalShow: false })
  }


  render() {
    const { getRightsForAccess } = this.props
    return (
      <div id='parent' >
        {this.state.querySuccess ? <Loading /> : ''}

        <ToastContainer />

        <table className="table table-sm small" >
          <thead>
            <tr key={'header'}>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Created At</th>
              <th>Last Updated At</th>
            </tr>
          </thead>
          <tbody ref={this.tableRef}>
            {this.state.userList.map((one, i) => {
              return (
                <React.Fragment key={i}>

                  <tr
                    key={i}

                  >
                    <td key={one.name}>{one.name}</td>
                    <td key={one.email}>{one.email}</td>
                    <td key={one.userType}>{one.userType}</td>
                    <td key={'created'}>{dateTime.getStringOfDateTime(one.createdAt)}</td>
                    <td key={'updated'}>{dateTime.getStringOfDateTime(one.updatedAt)}</td>
                    <td key='btn'>
                      {getRightsForAccess('StaticDataRights', 'UserMaster', 'update') || this.props.user.userType == 'user' ? <a className="btn btn-sm btn-primary collapsed"
                        data-target={`#${one.name}`}
                        data-toggle="collapse"
                        aria-expanded="false"
                        href={`#${one.name}`}
                        aria-controls={`${one.name}`}

                        onClick={() => { this.handleClickEdit(one) }}>
                        <FontAwesomeIcon icon={faEdit} />
                      </a> : ''}
                    </td>
                  </tr>

                  <UserRights
                    equipList={this.props.equipList}
                    updateUser={this.state.updateUser}
                    updateUserRights={this.state.updateUserRights}
                    handleClose={this.handleClose}
                    modalShow={this.state.modalShow}
                    history={this.props.history}
                  />

                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default UserRightTable;
