
import React, { useState } from 'react';
import date from '../utill/dateTime';
import Chart from 'react-google-charts';
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import Table from '../Common/table';
import moment from 'moment';
const ProductionCountDashboardTable = ({ extraParam, data }) => {
    const [modelState, setModelState] = useState(false);
    const [modelData, setModelData] = useState({});
    const [jobTableData, setJobTableData] = useState([]);

    const sortColumn = { path: 'From', order: 'asc' }

    const handleClickModelState = (one) => {
        setModelState(true);
        setModelData(one);
        let jobsArr = []
        one.Hours.forEach((hour) => {
            jobsArr = [...jobsArr, ...hour.Jobs]
        })
        setJobTableData(jobsArr);
    }

    const getTableData = (data2) => {
        let jobsArr = []
        if (Object.keys(data2).length > 0) {
            data2.Hours.forEach((hour) => {
                let arr = []
                hour.Jobs.forEach(job => {
                    let obj = { ...job };
                    // obj.Cycles=hour.Cycles;
                    // obj.IdealCycles=hour.IdealCycles;
                    arr.push(obj)
                })
                jobsArr = [...jobsArr, ...arr]
            })
        }

        return jobsArr;
    }




    const getChartData = (data1) => {
        let arr = []
        if (Object.keys(data1).length > 0) {
            let schema = ['Hours', 'Ideal', 'Actual']
            arr.push(schema);
            data1.Hours.forEach(element => {
                let arr1 = []
                arr1.push(date.getTime(element.From) + '-' + date.getTime(element.Till));
                arr1.push(parseInt(element.IdealCycles));
                arr1.push(element.Cycles);
                arr.push(arr1);
            });
        }


        return arr;
    }

    const columns = [
        { path: 'From', label: 'Date', content: (obj) => <label>{date.getDate(obj.From)}</label> },
        { path: 'From', label: 'From', content: (obj) => <label>{date.getTime(obj.From)}</label> },
        { path: 'Till', label: 'Till', content: (obj) => <label>{date.getTime(obj.Till)}</label> },
        { path: 'Component', label: 'Component' },
        { path: 'OperationID', label: 'Operation' },
        { path: 'ToolID', label: 'Tool' },
        { path: 'IdealCycles', label: 'Ideal Count' },
        { path: 'Cycles', label: 'Actual Count' },
    ]


    return (
        <>
            <table class="table table-sm table-hover small  table-striped w-100" style={{ fontSize: '16px' }}>
                <thead className='small text-center border'>
                    <tr className='table-primary '>
                        <th rowSpan='2' >Date</th>
                        <th rowSpan='2' >Shift</th>
                        <th rowSpan='2' >Equipment</th>
                        {data.length > 0 ? data[0].Hours.map(hour => {
                            return <th colSpan='1' ><div className='mt-2'><span>{date.getTime(new Date(hour.From))}-{date.getTime(new Date(hour.Till))}</span></div></th>
                        }) : ''}
                        <th rowSpan='1' ></th>

                    </tr>

                    <tr className='table-primary '>
                        {data.length > 0 ? data[0].Hours.map(hour => {
                            return <><th>Cycles Run{extraParam !== "None" ? ` / ${extraParam}` : ""}</th>
                                {/* <th>Actual</th> */}
                            </>
                        }) : ''}
                        <th rowSpan='2' >Total</th>

                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        data && data.length > 0 && data.map((one) => {
                            return <tr className='text-center' style={{ fontSize: '16px' }}>
                                <td>{moment(one.Hours[0].From).format('DD-MM')}</td>
                                <td>{one.Shift}</td>
                                <td>{one.Equipment}</td>
                                {/* <td>{one.EquipmentGroup}</td> */}
                                {/* {one.Hours.map(hour => {
                                    return <><td>{hour.IdealCycles}</td><td>{hour.Cycles}</td></>

                                })} */}
                                {one.Hours.map(hour => {
                                    return <><td>{extraParam !== "None" ? `${hour.CyclesRun} / ${hour[extraParam]}` : hour.CyclesRun}</td></>

                                })}
                                <td colSpan="1">{(one.Hours.reduce(
                                    (accumulator, currentValue) => {
                                        return accumulator + parseFloat(currentValue.CyclesRun)
                                    },
                                    0,
                                )).toFixed(1)}</td>
                            </tr>
                        })
                    }


                </tbody>



            </table>

        </>
    );
}

export default ProductionCountDashboardTable;