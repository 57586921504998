import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFileFull, generateExcelFile } from '../utill/download'

import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import EquipmentSelection from '../Common/equipmentSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import MultiSelectCheckBox from '../Common/MultiSelectCheckBox';
import HourlyTableProdction from '../Common/hourlyProductionTable';
import DateSelection from '../Common/DateSelection';
import { ToastContainer, toast } from 'react-toastify';
import { Radio } from 'antd';

const ProductionReportHourly = ({ user, equipGroups, equips, fromDateLimit }) => {
  const [productionData, setProductionData] = useState([])
  const [inputData, setInputData] = useState({
    From: '', // Initialize From date state
    Till: ''
  })
  const [errors, setErrors] = useState({})
  const [index, setIndex] = useState(0)
  const [querySuccess, setQuerySuccess] = useState(false)
  const [shiftSetting, setShiftSetting] = useState([])
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [selectedValue, setSelectedValue] = useState("None");
  const [shiftOption, setShiftOption] = useState([
    { label: 'Shift1', value: 'Shift1', checked: false },
    { label: 'Shift2', value: 'Shift2', checked: false },
    { label: 'Shift3', value: 'Shift3', checked: false },
    { label: 'Shift4', value: 'Shift4', checked: false }])



  const [defaultDate, setDefaultDate] = useState('');

  const keyParam = localStorage.getItem("prod_hourly_count_report");
  useEffect(() => {
    if (keyParam) {
      setSelectedValue(keyParam);
    }
  }, [keyParam]);

  useEffect(() => {
    // Function to get today's date in 'YYYY-MM-DD' format
    const getTodayDate = () => {
      const today = new Date();
      let month = String(today.getMonth() + 1).padStart(2, '0');
      let day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();

      return `${year}-${month}-${day}`;
    };
    // Set today's date as the default value
    setDefaultDate(getTodayDate());
    let preSelectedDate = {
      From: getTodayDate(),
      Till: getTodayDate()
    }
    handleOnChange({ currentTarget: { name: 'Shift', value: 'All', checked: true } });

    setInputData(preSelectedDate);
  }, []);


  const setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option)
      setSelectedEquipOption([])
    }
    if (key == 'Equipment') {

      setSelectedEquipOption(option)
    }
  }

  const getEquipsForReport = () => {
    let equipArr = []
    equips.forEach((one) => {
      selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      })
    })
    return equipArr;
  }
  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    Shift: joi.array().min(1).required().label('please select at least one shift'),
    UserId: joi.string(),
    Equips: joi.array()
  }

  const sortColumnComponent = { path: 'Component', order: 'asc' }
  const sortColumnMachine = { path: 'Machine', order: 'asc' }
  const sortColumnOperator = { path: 'Operator', order: 'asc' }

  const sortColumnDate = { path: 'Date', order: 'asc' }
  const columnsComponent = [
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'OperationId' },
    { path: 'Operator', label: 'Operator' },
    { path: 'Equipment', label: 'Machine' },
    {
      path: 'Date', label: 'Date', content: obj => <React.Fragment>
        <label>
          {date.getDate(obj.From)}
        </label>
      </React.Fragment>
    },
    { path: 'Shift', label: 'Shift' },
    { path: 'ActualProduction', label: 'Total Qty.' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]
  const columnsOperator = [
    { path: 'Operator', label: 'Operator' },
    { path: 'Equipment', label: 'Machine' },
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'OperationId' },
    { path: 'Date', label: 'Date' },
    { path: 'Shift', label: 'Shift' },
    { path: 'ActualProduction', label: 'Total Qty.' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]
  const columnsMachine = [
    { path: 'Equipment', label: 'Machine' },
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'OperationId' },
    { path: 'Operator', label: 'Operator' },
    { path: 'Date', label: 'Date' },
    { path: 'Shift', label: 'Shift' },
    { path: 'ActualProduction', label: 'Total Qty.' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]

  const getProductionReportSchema = () => {
    let schema = []
    productionData.forEach((one) => {
      let obj = {}
      obj.path = 'Date'
      obj.label = 'Date'
      obj.content = (o) => { return <label>{date.getDateFormat(o.From)}</label> }

      let obj1 = {}
      obj1.path = 'Equipment'
      obj1.label = 'Equipment'
      let obj2 = {}
      obj2.path = 'EquipmentGroup'
      obj2.label = 'EquipmentGroup'
      schema.push(obj)
      schema.push(obj1)
      schema.push(obj2)
      one.Hours.forEach(hour => {
        let obj4 = {}
        obj4.key = 'hour'
        obj4.label = `${date.getTime(hour.From)}-${date.getTime(hour.Till)}`
        obj4.content = (o) => { return <label>{o.Cycles}</label> }
        schema.push(obj4);
      })
    })

    return schema;
  }

  const ProductionReportSchema = [
    { path: 'Date', label: 'Date' },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'EquipmentGroup', label: 'EquipmentGroup' },
    { path: 'Hour', label: 'Hour1' },
    { path: 'Hour', label: 'Hour2' },
    { path: 'Hour', label: 'Hour3' }
  ]
  const SelectedDates = (dates) => {
    setInputData(dates)
  }
  // const handleOnChange = async (e) => {
  //   if (e.currentTarget.type === 'date') {
  //     inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
  //   }
  //   else {
  //     inputData[e.currentTarget.name] = e.currentTarget.value;
  //   }
  //   let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
  //   setShiftSetting(res.data);
  //   setInputData(inputData);
  //   setErrors({})
  // }
  const convertDateFormat = (dateString) => {
    const [month, day, year] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  const handleOnChange = async (e) => {

    if (e.currentTarget.type === 'date') {
      const { name, value } = e.target;
      // inputData[e.currentTarget.name]= convertDateFormat(e.currentTarget.value);
      let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
      setShiftSetting(res.data);
      setInputData(prevState => ({
        ...prevState,
        [name]: convertDateFormat(value) // Convert date format before setting
      }));
    }
    else if (e.currentTarget.type === 'time') {
      let Time = e.currentTarget.value.split(':');
      inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
      setInputData(inputData);
    }
    else if (e.currentTarget.name == 'Shift') {
      const options = [...shiftOption];
      if (e.currentTarget.value && (e.currentTarget.value == 'All')) {
        options.forEach(one => one.checked = e.currentTarget.checked);
      } else {
        options.forEach(one => {
          if (one.value == e.currentTarget.value) {
            one.checked = e.currentTarget.checked
          }
        });
      }
      // inputData[e.currentTarget.name] = options.filter(one=>one.checked);
      setInputData(prevState => ({
        ...prevState,
        [e.currentTarget.name]: options.filter(one => one.checked == true)  // Convert date format before setting
      }));
      setShiftOption(options);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value
    }
    setErrors({})
    // console.log(inputData);

  }

  const GetDailyFrames = (FromDate, TillDate) => {
    let frame = []
    let from = new Date(new Date(FromDate).setHours(0, 0, 0, 0));
    let till = new Date(new Date(TillDate).setHours(0, 0, 0, 0));
    // from.setHours(from.getHours()-5);
    // from.setMinutes(from.getMinutes()-30);

    // till.setHours(till.getHours()-5);
    // till.setMinutes(till.getMinutes()-30);
    while (from <= till) {
      let obj = { From: new Date(from) };
      from.setHours(23, 59, 59, 999)
      obj.Till = new Date(from);
      frame.push(obj);
      from.setDate(from.getDate() + 1)
      from.setHours(0, 0, 0, 0);


    }
    return frame
  }
  const handleOnSubmit = async (inputData) => {
    inputData = { ...inputData }
    inputData['Shift'] = shiftOption.filter(shift => shift.checked === true);
    productionData.splice(0, productionData.length);
    setIndex(0)
    const errors = validation.validate(inputData, schema);
    // console.log(errors)
    setErrors(errors || {});
    if (errors) return;


    setQuerySuccess(true);
    inputData.UserId = user._id
    let equipForReort = getEquipsForReport()
    for (let i = 0; i < equipForReort.length; i++) {
      try {
        let list = GetDailyFrames(new Date(inputData.From), new Date(inputData.Till));
        for (let frame = 0; frame < list.length; frame++) {
          let obj = { Shift: inputData.Shift.map(one => one.value), UserId: user._id, From: list[frame].From, Till: list[frame].Till, Equips: [equipForReort[i]] }
          let result = await axios.post(api.api + 'GetDataForProductionReportHourly', obj)
          if (result.data !== null && result.data !== undefined) {
            if (result.data.length > 0) {
              // console.log(result.data,"data to test")/
              result.data.forEach((one) => {
                productionData.push(one);
              })
              setProductionData(productionData)
              let idx = i + 1

              setIndex(idx)

            }
          }
          else {
            setQuerySuccess(false)
          }
        }





      }
      catch (err) {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // console.log(err)
        setQuerySuccess(false)
        break;
      }
      // }

    }
    setQuerySuccess(false)


  }






  const handleSort = (sortColumn) => {
    if (productionData.length > 0) {
      const sorted = _.orderBy(
        productionData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setProductionData(sorted)
    }
  }

  const handleDownload = (e) => {
    generateExcelFile(e)
  }

  const handleDownloadFullExcel = (data, view) => {
    let jobsArr = []
    data.forEach((one) => {
      if (Object.keys(one).length > 0) {
        one.Hours.forEach((hour) => {
          let arr = []
          hour.Jobs.forEach(job => {
            let obj = { ...job };
            obj.ActualCycles = hour.Cycles;
            obj.IdealCycles = hour.IdealCycles;
            delete obj.modified;
            delete obj.Cycles;
            delete obj.createdAt;
            delete obj.TargetQty;
            delete obj.updatedAt;
            delete obj.CustomerName;
            delete obj.DocumentType;
            delete obj.__v;
            delete obj._id;
            obj.Tool = obj.ToolID;
            delete obj.ToolID;
            var options = { hour12: false };
            obj.From = new Date(obj.From).toLocaleString('en-US', options)
            obj.Till = new Date(obj.Till).toLocaleString('en-US', options)
            let fromDateTime = obj.From.split(',');
            let tillDateTime = obj.Till.split(',');
            obj.FromDate = fromDateTime[0];
            obj.FromTime = fromDateTime[1];
            obj.TillDate = tillDateTime[0];
            obj.TillTime = tillDateTime[1];
            delete obj.From;
            delete obj.Till;
            arr.push(obj)
          })
          jobsArr = [...jobsArr, ...arr]
        })
      }
    })
    generateExcelFileFull(jobsArr, view)
  }


  return (
    <React.Fragment>
      <ToastContainer />
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Production Hourly
        </h4>
      </div>
      <div className="row mx-2">
        {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input
      className='form-control form-control-sm'
      min={fromDateLimit}
      type='date'
      onChange={handleOnChange}
      name='From'
      value={inputData.From} // Set the default date value here
    />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
        </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input  className='form-control form-control-sm' type='date' onChange={handleOnChange} 
              value={inputData.Till} // Set the default date value here
              name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div> */}
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            {<MultiSelectCheckBox name='Shift' onChange={handleOnChange} selectedValues={shiftOption} options={shiftOption} />}
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>

        {productionData.length > 0 && <div className="col-sm-2">
          <button disabled={productionData.length > 0 ? false : true} className='btn btn-primary btn-sm mt-2' onClick={() => handleDownload('HourlyProductionReport')}>Download Excel &nbsp; <FontAwesomeIcon icon={faFileExcel} /></button>
        </div>}
        {productionData.length > 0 && <div className="col-sm-2">
          <button disabled={productionData.length > 0 ? false : true} className='btn btn-primary btn-sm mt-2' onClick={() => handleDownloadFullExcel(productionData, 'HourlyProductionDetailedReport')}>Download Detail&nbsp;<FontAwesomeIcon icon={faFileExcel} /></button>
        </div>}
        {productionData.length > 0 && <div className="col-sm-4">
          <Radio.Group onChange={(e) => { setSelectedValue(e.target.value); localStorage.setItem("prod_hourly_count_report", e.target.value) }} value={selectedValue} buttonStyle="solid">
            <Radio value={"AvgSPM"}>Average SPM</Radio>
            <Radio value={"AvgCycleTime"} >Average Cycle Time</Radio>
            <Radio value={"None"} >None</Radio>
          </Radio.Group>
        </div>}
      </div>
      <div className='mt-2'>
        {productionData.length > 0 ? <HourlyTableProdction extraParam={selectedValue} data={productionData} /> : ''}

        {/* <Table columns={getProductionReportSchema} onSort={handleSort} sortColumn={sortColumnDate} data={productionData} /> */}
      </div>
    </React.Fragment>
  );
}
export default ProductionReportHourly;