import jwtDecode from "jwt-decode";
import http from "./httpService";
import api from '../config';
import swal  from 'sweetalert';

const apiEndpoint = api.api + "login";
const tokenKey = "token";


// consol('bhdvhsdh')
// consol(getJwt())
http.setJwt(getJwt());
//http.setDataRights(getDataRights())

// const errorFlag=http.setJwt(getJwt());
// if(errorFlag)
// logout()



export async function login(user,cb) {
  try{
    const jwt= await http.post(apiEndpoint, user,{headers:{Company:user.company}})
    cb(null,jwt)
  }
  catch(err){
    cb(err,null)
  }
 
 
 // localStorage.setItem(tokenKey, jwt); 
 
}
export async function subscribe(email, subscription) {
  const subscribe= await http.post(api.api+'subscribe', { email:email, subscription:subscription }).catch((err)=>{
    // consol(err)
  });
 
 // localStorage.setItem(tokenKey, jwt); 
  return subscribe;
}
export async function userActiveStatus(email) {
  const status= await http.get(api.api+'checkUserIsActive/'+email).catch((err)=>{
    if(err){
      // consol(err);
      return err
    }
  });
 
 // localStorage.setItem(tokenKey, jwt); 
  return status;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout(email) {
  // console.log('logout', email);
  localStorage.removeItem(tokenKey);
  localStorage.clear();
  sessionStorage.clear();
  
  await  http.get(api.api+'logout/'+email)
 
}
export function checkTokenExpiration()
{
  const jwt = localStorage.getItem(tokenKey);
  if(jwt!==null)
    {
      if (jwtDecode(jwt).exp < Date.now() / 1000) {
      
        localStorage.clear();
      }
    }

}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    // consol(jwt)
    http.setJwt(jwt);
    const user=jwtDecode(jwt); 
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}
// export function getDataRights() {
//   return ;
// }

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  checkTokenExpiration,
  subscribe,
  userActiveStatus
};
